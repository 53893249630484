import React, { useCallback } from 'react';
import {
  CustomizedTooltipField,
  CustomizedTooltipFieldValue
} from '../../../components/Graphic/components/CustomizedTooltip';
import { SelectedPoint } from '../../../components';

export const useRenderTooltip = (field?: string, noBatch?: boolean) =>
  useCallback(
    (payload: SelectedPoint<string>) => {
      return (
        <div>
          {!noBatch && (
            <CustomizedTooltipField>
              Batch ID:{' '}
              <CustomizedTooltipFieldValue>
                {payload.dataKey}
              </CustomizedTooltipFieldValue>
            </CustomizedTooltipField>
          )}
          <CustomizedTooltipField>
            Time:{' '}
            <CustomizedTooltipFieldValue>
              {payload.x}
            </CustomizedTooltipFieldValue>
          </CustomizedTooltipField>
          <CustomizedTooltipField>
            {field}:{' '}
            <CustomizedTooltipFieldValue>
              {payload.y}
            </CustomizedTooltipFieldValue>
          </CustomizedTooltipField>
        </div>
      );
    },
    [field]
  );
