import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERIES } from './queriesEnum';
import {
  SimulationApi,
  SimulationInitialConditions,
  SimulationParameters,
  Stage
} from '../api/simulation';
import { MUTATIONS } from './mutationsEnum';
import { SimulationMode } from '../pages/simulation';
import { useCallback, useMemo } from 'react';

export const useGrowthKinetics = ({
  projectId
}: {
  projectId?: string | null;
}) =>
  useQuery(
    useMemo(() => [QUERIES.GROWTH_KINETICS, projectId], [projectId]),
    useCallback(() => {
      return SimulationApi.getGrowthKinetics({
        projectId: projectId as string
      });
    }, [projectId]),
    useMemo(
      () => ({
        enabled: Boolean(projectId)
      }),
      [projectId]
    )
  );

export const useStages = ({ projectId }: { projectId?: string }) =>
  useQuery(
    useMemo(() => [QUERIES.STAGES, projectId], [projectId]),
    useCallback(() => {
      return SimulationApi.getStages({
        projectId: projectId as string
      });
    }, [projectId]),
    useMemo(
      () => ({
        enabled: Boolean(projectId)
      }),
      [projectId]
    )
  );

export const useAddStage = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    useCallback(() => {
      return SimulationApi.addStage({
        projectId
      });
    }, [projectId]),
    useMemo(
      () => ({
        mutationKey: MUTATIONS.CREATE_STAGE,
        onSuccess() {
          queryClient.refetchQueries([QUERIES.STAGES, projectId]);
        }
      }),
      [queryClient, projectId]
    )
  );
};

export const useEditStage = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    useCallback(
      ({ parameterId, stage }: { parameterId: string; stage: Stage }) => {
        return SimulationApi.editStage({
          parameterId,
          projectId,
          stage
        });
      },
      [projectId]
    ),
    useMemo(
      () => ({
        mutationKey: MUTATIONS.EDIT_STAGE,
        onSuccess() {
          // queryClient.refetchQueries([QUERIES.STAGES, projectId]);
        }
      }),
      [queryClient, projectId]
    )
  );
};

export const useDeleteStage = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    useCallback(({ parameterId }: { parameterId: string }) => {
      return SimulationApi.deleteStage({
        parameterId
      });
    }, []),
    useMemo(
      () => ({
        mutationKey: MUTATIONS.DELETE_STAGE,
        onSuccess() {
          console.log('refetch');
          queryClient.refetchQueries([QUERIES.STAGES, projectId]);
        }
      }),
      [queryClient, projectId]
    )
  );
};
export const useUpdateGrowthKinetics = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    useCallback(({ batchId, data }: { batchId: string; data: object }) => {
      return SimulationApi.recalculateGrowthKinetics({
        projectId,
        batchId,
        data
      });
    }, []),
    useMemo(
      () => ({
        mutationKey: MUTATIONS.UPDATE_GROWTH_KINETICS,
        onSuccess() {
          queryClient.refetchQueries(QUERIES.INITIAL_DATA);
          queryClient.refetchQueries([QUERIES.GROWTH_KINETICS, projectId]);
        }
      }),
      [queryClient, projectId]
    )
  );
};

export const useRunSimulation = (projectId: string) =>
  useMutation(MUTATIONS.RUN_SIMULATION, () =>
    SimulationApi.runSimulation({ projectId })
  );
export const useRunDTSimulation = (projectId: string) =>
  useMutation(MUTATIONS.RUN_DT_SIMULATION, (simulationDuration: number) =>
    SimulationApi.runDtSimulation(projectId, simulationDuration)
  );

export const useExport = (projectId: string) =>
  useMutation(
    MUTATIONS.EXPORT,
    (options: {
      ORIGINAL_DATA: boolean;
      METABOLITE_DATES: boolean;
      PREDICTED_MODEL_DATA: boolean;
    }) => SimulationApi.export(projectId, options)
  );
