import React, { useCallback } from 'react';
import { XAxis } from 'recharts';

export interface CustomizedXAxisProps {
  dataKey: string;
  label: string;
}

export const useRenderCustomizedXAxis = ({
  dataKey,
  label
}: CustomizedXAxisProps) => {
  return useCallback(
    () => (
      <XAxis
        type="number"
        allowDuplicatedCategory={false}
        dataKey={dataKey}
        height={70}
        label={<div style={{ marginLeft: '10px' }}>{label}</div>}
        // padding={{ left: 100, right: 100 }}
      />
    ),
    [dataKey, label]
  );
};
