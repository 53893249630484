import { Header } from '../../components/Header/Header';
import { ProjectBreadcrumbs } from '../project-settings';
import s from './model-configuring.module.scss';
import { Graphic, Heading, HeadingVariant } from '../../components';
import { LastSaved } from '../model-configuring';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { read, utils, writeFileXLSX } from 'xlsx';
import {
  FieldSpec,
  FormWrapper,
  useValidate
} from '../project-settings/components/FormContext';
import { Info } from './index';
import { Button, ButtonVariant } from '../../components/Button/Button';
import { Table } from '../../components/Table/Table';
import { prepareResponse } from './__mocks__/responsePrepared';
import { useRenderTooltip } from '../fitting/hooks/useRenderTooltip';
import { useLines } from './hooks/useLines';
import { useXAxis } from './hooks/useXAxis';
import { MultiSelect } from '../../components/MultiSelect/MultiSelect';
import {
  useAddStage,
  useDeleteStage,
  useEditStage,
  useRunSimulation,
  useStages
} from '../../queries/simulation';
import { useParams } from 'react-router-dom';
import { Stage as StageType } from '../../api/simulation';
import {
  useInitialConditions,
  useProject,
  useUpdateInitialConditions
} from '../../queries/projects';
import { LoadingIcon } from './LoadingIcon';
import { useIsMutating, useQueryClient } from 'react-query';

const initialConditionsFieldsSpec: FieldSpec[] = [
  {
    name: 'initial_viability',
    type: 'float',
    required: true,
    min: 0.5,
    max: 1
  },
  {
    name: 'initial_viable_cell_density',
    type: 'float',
    required: true,
    min: 0.1,
    max: 20
  },
  {
    name: 'initial_biomaterial',
    type: 'float',
    required: true,
    min: 0,
    max: 1000
  },
  {
    name: 'initial_lysed_cell_density',
    type: 'float',
    required: true,
    min: 0,
    max: 100
  }
];

const initialConditionsDefault = {
  initial_viability: 0.94,
  initial_viable_cell_density: 0.2,
  initial_biomaterial: 0,
  initial_lysed_cell_density: 0
};

const uiFields = [
  {
    value: 'initial_viability',
    name: 'Initial viability',
    unit: '0 - 1.0'
  },
  {
    value: 'initial_viable_cell_density',
    name: 'Initial viable cell density',
    unit: '1e6 cells/ml'
  },
  {
    value: 'initial_biomaterial',
    name: 'Initial biomaterial',
    unit: '1e6 parts/ml'
  },
  {
    value: 'initial_lysed_cell_density',
    name: 'Initial lysed cell density',
    unit: '1e6 cells/ml'
  }
];

const iniToUi = (ini: {
  'Viable cell density': number;
  'Bio material': number;
  'Lysed cell density': number;
  'Cell viability': number;
}) => ({
  initial_viability: ini['Cell viability'],
  initial_viable_cell_density: ini['Viable cell density'],
  initial_biomaterial: ini['Bio material'],
  initial_lysed_cell_density: ini['Lysed cell density']
});

const ValidateInitialConditions = ({ fields }: { fields: any }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { mutateAsync } = useUpdateInitialConditions(projectId);
  const validate = useValidate();

  useEffect(() => {
    if (Object.keys(validate()).length === 0) {
      if (
        'initial_biomaterial' in fields &&
        'initial_lysed_cell_density' in fields &&
        'initial_viability' in fields &&
        'initial_viable_cell_density' in fields
      ) {
        mutateAsync(fields as any);
      }
    }
  }, [fields, mutateAsync, validate]);
  return null;
};

const InitialConditionsFormChild = ({ initial }: { initial: object }) => {
  const [fields, setFields] = useState(initial);

  return (
    <FormWrapper
      defaultValues={initial}
      fieldsSpec={initialConditionsFieldsSpec}
      fields={fields}
      setFields={setFields}
    >
      <ValidateInitialConditions fields={fields} />
      <Info title="Initial conditions" fields={uiFields} />
    </FormWrapper>
  );
};

const InitialConditionsFormViewOnly = ({ initial }: { initial: object }) => {
  return (
    <FormWrapper
      defaultValues={initial}
      fieldsSpec={initialConditionsFieldsSpec}
      fields={initial}
      setFields={() => void 0}
    >
      {/*<ValidateInitialConditions fields={initial} />*/}
      <Info
        disabled
        title="Initial conditions(stage was runned with)"
        fields={uiFields}
      />
    </FormWrapper>
  );
};
const InitialConditionsForm = ({ disabled }: { disabled?: boolean }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data, isLoading, isSuccess } = useInitialConditions(projectId);
  const initial = useMemo(() => {
    const obj = data?.data as any;
    if (!obj) return null;
    return {
      initial_viability: obj.initial_viability,
      initial_viable_cell_density: obj.initial_viable_cell_density,
      initial_biomaterial: obj.initial_biomaterial,
      initial_lysed_cell_density: obj.initial_lysed_cell_density
    };
  }, [data]);

  console.log('>>> data:', data);
  if (isLoading || !isSuccess || !initial || disabled) {
    return null;
  }

  return <InitialConditionsFormChild initial={initial} />;
};

const modeOptions = [
  { value: 'FED_BUTCH', label: 'Fed-batch' },
  { value: 'INTENSIFIED_GROWTH', label: 'Intensified growth' },
  { value: 'CONTINUOUS_PERFUSION', label: 'Continuous perfusion' }
];

const stageSettingsFieldsSpec: { [key: string]: FieldSpec[] } = {
  FED_BUTCH: [
    {
      name: 'volume',
      type: 'float',
      min: 0.000001,
      max: 100000,
      required: true,
      options: [
        { label: '0.005', value: 0.005 },
        { label: '0.01', value: 0.01 },
        { label: '0.1', value: 0.1 },
        { label: '1.0', value: 1.0 },
        { label: '10', value: 10 },
        { label: '50', value: 50 },
        { label: '1000', value: 1000 }
      ]
    },
    {
      name: 'simulation_duration',
      type: 'float',
      min: 0.000001,
      max: 100,
      required: true
    }
  ],
  INTENSIFIED_GROWTH: [
    {
      name: 'volume',
      type: 'float',
      min: 0.000001,
      max: 100000,
      required: true,
      options: [
        { label: '0.005', value: 0.005 },
        { label: '0.01', value: 0.01 },
        { label: '0.1', value: 0.1 },
        { label: '1.0', value: 1.0 },
        { label: '10', value: 10 },
        { label: '50', value: 50 },
        { label: '1000', value: 1000 }
      ]
    },
    {
      name: 'simulation_duration',
      type: 'float',
      min: 0.000001,
      max: 100,
      required: true
    },
    {
      name: 'cell_specific_exchange_rate',
      type: 'float',
      min: 0,
      max: 200,
      required: true
    }
  ],
  CONTINUOUS_PERFUSION: [
    {
      name: 'volume',
      type: 'float',
      min: 0.000001,
      max: 100000,
      required: true,
      options: [
        { label: '0.005', value: 0.005 },
        { label: '0.01', value: 0.01 },
        { label: '0.1', value: 0.1 },
        { label: '1.0', value: 1.0 },
        { label: '10', value: 10 },
        { label: '50', value: 50 },
        { label: '1000', value: 1000 }
      ]
    },
    {
      name: 'simulation_duration',
      type: 'float',
      min: 0.000001,
      required: true,
      max: 100
    },
    {
      name: 'volumetric_exchange_rate',
      type: 'float',
      min: 0,
      required: true,
      max: 5
    },
    {
      name: 'target_viable_cell_denisty',
      type: 'float',
      min: 0,
      required: true,
      max: 200
    }
  ]
};

const uiFieldsStageSettings: any = {
  FED_BUTCH: [
    {
      value: 'volume',
      name: 'Working Volume',
      unit: 'L',
      type: 'select-or-float'
    },
    {
      value: 'simulation_duration',
      name: 'Duration (days)',
      unit: 'days'
    }
  ],
  INTENSIFIED_GROWTH: [
    {
      value: 'volume',
      name: 'Working Volume',
      unit: 'L',
      type: 'select-or-float'
    },
    {
      value: 'simulation_duration',
      name: 'Duration (days)',
      unit: 'days'
    },
    {
      value: 'cell_specific_exchange_rate',
      name: 'Cell specific exchange rate',
      unit: 'pL/cell/day'
    }
  ],
  CONTINUOUS_PERFUSION: [
    {
      value: 'volume',
      name: 'Working Volume',
      unit: 'L',
      type: 'select-or-float'
    },
    {
      value: 'simulation_duration',
      name: 'Duration (days)',
      unit: 'days'
    },
    {
      value: 'volumetric_exchange_rate',
      name: 'Volumetric exchange rate',
      unit: 'vol/day'
    },
    {
      value: 'target_viable_cell_denisty',
      name: 'Target viable cell density',
      unit: '1e6 cells/ml'
    }
  ]
} as const;

const ValidateAndUpdate = ({
  parameterId,
  stage
}: {
  parameterId: string;
  stage: StageType;
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const validate = useValidate();
  const isFirst = useRef(true);
  const timeoutId = useRef<number | null>(null);
  const { mutateAsync: editStage } = useEditStage(projectId);
  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }
    if (Object.keys(validate()).length === 0) {
      timeoutId.current = setTimeout(() => {
        console.log('>>> stage:', stage);
        editStage({
          parameterId,
          stage
        });
      }, 300) as any;
      return () => clearTimeout(timeoutId.current ?? 0);
    }
  }, [editStage, parameterId, stage, validate]);
  return null;
};
const stageSettingsDefault = {
  simulation_duration: 12,
  cell_specific_exchange_rate: 40,
  volumetric_exchange_rate: 2,
  target_viable_cell_denisty: 80
};

const defaultDurationBySimulationType = {
  FED_BUTCH: 12,
  INTENSIFIED_GROWTH: 7,
  CONTINUOUS_PERFUSION: 30
};
const StageSettingsInitialConditionsForm = ({
  simulationType,
  initial
}: {
  simulationType: string;
  initial: {
    cell_specific_exchange_rate: number;
    id: string;
    parameter_type: string;
    project: string;
    simulation_duration: number;
    target_viable_cell_denisty: number;
    volume: number;
    volumetric_exchange_rate: number;
  };
}) => {
  const [fields, setFields] = useState<any>(initial);

  const stage = useMemo(
    () => ({ ...fields, parameter_type: simulationType as any }),
    [fields, simulationType]
  );

  const prevSimulationType = useRef(simulationType);
  useEffect(() => {
    if (prevSimulationType.current !== simulationType) {
      prevSimulationType.current = simulationType;

      console.log('i updated');
      setFields((prev: any) => ({
        ...prev,
        simulation_duration: (defaultDurationBySimulationType as any)[
          simulationType
        ]
      }));
    }
  }, [simulationType]);

  console.log('>>> stage:', stage);
  return (
    <FormWrapper
      defaultValues={initial}
      fieldsSpec={stageSettingsFieldsSpec[simulationType]}
      fields={fields}
      setFields={setFields}
    >
      {simulationType && (
        <ValidateAndUpdate parameterId={initial.id} stage={stage} />
      )}
      <Info
        title="Stage settings"
        fields={uiFieldsStageSettings[simulationType]}
      />
    </FormWrapper>
  );
};
interface StageProps {
  stageNumber: number;
  onAdd?: () => void;
  onDelete?: () => void;
  data?: any;
  initial: {
    cell_specific_exchange_rate: number;
    id: string;
    parameter_type: string;
    project: string;
    simulation_duration: number;
    target_viable_cell_denisty: number;
    volume: number;
    volumetric_exchange_rate: number;
  };
  isDeleteDisabled?: boolean;
  initial_conditions?: null | object;
}
const Stage = ({
  initial,
  stageNumber,
  onAdd,
  onDelete,
  data,
  isDeleteDisabled,
  initial_conditions
}: StageProps) => {
  console.log('>>> initial:', initial);
  const [simulationType, setSimulationType] = useState(
    initial.parameter_type || null
  );
  const tableRows = useMemo(() => {
    return (
      data?.map(({ batchName, data }: any) => {
        const parameterNames = Object.keys(data);
        const res: { [key: string]: string | number } = {
          BatchId: batchName
        };
        parameterNames.forEach((parameterName) => {
          res[parameterName] = (data as any)[parameterName][
            (data as any)[parameterName].length - 1
          ];
        });
        return res;
      }) || []
    );
  }, [data]);
  const tableColumns = useMemo(() => {
    return Object.keys(
      tableRows.reduce((acc: any, row: any) => ({ ...acc, ...row }), {})
    ).filter((c) => c !== 'Time' && c !== 'BatchId');
  }, [tableRows]);
  const variableSelector2 = useMemo(() => {
    return data
      ?.flatMap(({ batchName, data }: any) =>
        (data as any)['Viable cell density'].map((v: number, idx: number) => ({
          [batchName]: (data as any)['Viable cell density'][idx],
          x: (data as any)['Time']?.[idx],
          batchId: batchName
        }))
      )
      .sort((a: any, b: any) => a.x - b.x);
  }, [data]);
  const variableSelector3 = useMemo(() => {
    return data
      ?.flatMap(({ batchName, data }: any) =>
        (data as any)['Viable cell density'].map((v: number, idx: number) => ({
          [batchName]: (data as any)['Cell viability'][idx],
          x: (data as any)['Time']?.[idx],
          batchId: batchName
        }))
      )
      .sort((a: any, b: any) => a.x - b.x);
  }, [data]);
  const variableSelector4 = useMemo(() => {
    return data
      ?.flatMap(({ batchName, data }: any) =>
        (data as any)['Viable cell density'].map((v: number, idx: number) => ({
          [batchName]: (data as any)['Total cells'][idx],
          x: (data as any)['Time']?.[idx],
          batchId: batchName
        }))
      )
      .sort((a: any, b: any) => a.x - b.x);
  }, [data]);
  const selected2RenderTooltip = useRenderTooltip('Viable cell density', true);
  const selected3RenderTooltip = useRenderTooltip('Viability', true);
  const selected4RenderTooltip = useRenderTooltip('Total viable cells', true);
  const lines = useLines(data);
  const xAxis = useXAxis();
  console.log('BUILD BUILD BUILD');
  return (
    <div className={s.stageWrap}>
      <div className={s.stage}>
        <div className={s.stageTitle}>Seed stage {stageNumber}</div>

        <div className={s.stageContent}>
          <div className={s.stageBlocks}>
            <MultiSelect
              onlyOne
              value={useMemo(() => {
                return simulationType ? [simulationType] : [];
              }, [simulationType])}
              onChange={(value) => {
                setSimulationType(value[0]);
              }}
              options={modeOptions}
            />
            {simulationType && (
              <StageSettingsInitialConditionsForm
                initial={initial}
                simulationType={simulationType}
              />
            )}
            {stageNumber !== 1 ? (
              initial_conditions ? (
                <InitialConditionsFormViewOnly
                  initial={iniToUi(initial_conditions as any)}
                />
              ) : null
            ) : (
              <InitialConditionsForm disabled={stageNumber !== 1} />
            )}
          </div>
          <div style={{ overflow: 'hidden' }}>
            <div className={s.graphics}>
              <Graphic
                data={variableSelector2}
                yAxisUnit={'Viable cell density'}
                renderTooltip={selected2RenderTooltip}
                showIncludeExcludeControls={false}
                lines={lines}
                xAxis={xAxis}
                customYAxisWidth={140}
              />
              <Graphic
                data={variableSelector3}
                yAxisUnit={'Viability'}
                renderTooltip={selected3RenderTooltip}
                showIncludeExcludeControls={false}
                lines={lines}
                xAxis={xAxis}
                customYAxisWidth={140}
              />
              <Graphic
                data={variableSelector4}
                yAxisUnit="Total viable cells"
                renderTooltip={selected4RenderTooltip}
                showIncludeExcludeControls={false}
                lines={lines}
                xAxis={xAxis}
                customYAxisWidth={140}
              />
            </div>
            {data ? (
              <div className={s.ModelConfiguring__table}>
                <Table
                  showSorting
                  rows={tableRows.map((row: any) => {
                    return { ...row, selected: false };
                  })}
                  columns={tableColumns.map((col) => ({
                    name: col,
                    title: col
                  }))}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={s.stageButtons}>
        {isDeleteDisabled ? null : (
          <Button onClick={onDelete}>Remove stage</Button>
        )}
        <Button onClick={onAdd}>Add stage</Button>
      </div>
    </div>
  );
};
export const SimulationPage = () => {
  // const [isSimulation, setSimulation] = useState(false);
  // const [stages, setStages] = useState<{ id: string }[]>([
  //   { id: new Date().toString() }
  // ]);
  // const client = useQueryClient();
  const isMutating = useIsMutating();

  const { projectId } = useParams<{ projectId: string }>();
  const project = useProject(projectId);
  const {
    mutate: mutateRunSimulation,
    data = [] as any,
    isLoading: isLoadingSim
  } = useRunSimulation(projectId);

  const prepared = useMemo(() => {
    if (typeof data?.data !== 'object') return [];
    return Object.keys(data.data)
      .map((key) => data.data[key])
      .map((o) => ({
        initial_conditions: o.initial_conditions,
        simRes: prepareResponse(o.simulation_result)
      }));
  }, [data?.data]);

  const onExport = useCallback(() => {
    const pres = Object.keys(data.data)
      .map((key) => data.data[key])
      .map((o, index) => {
        const data = prepareResponse(o.simulation_result)[0].data;
        const prep = Object.keys(data).reduce(
          (acc, key) => ({
            ...acc,
            [key]: (data as any)[key][(data as any)[key].length - 1]
          }),
          {}
        );
        return {
          Stage: `Stage ${index + 1}`,
          ...prep
        };
      });
    console.log(pres);
    const ws = utils.json_to_sheet(pres);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `${project?.data?.name}_simulation export.xlsx`);
  }, [data.data, project?.data?.name]);
  const onRunSimulation = useCallback(() => {
    mutateRunSimulation();
  }, [mutateRunSimulation]);

  const { data: stages, isLoading, isSuccess } = useStages({ projectId });
  const { mutateAsync: addStageOnBack } = useAddStage(projectId);
  const { mutateAsync: onDeleteStage } = useDeleteStage(projectId);
  const addStage = useCallback(() => {
    addStageOnBack();
  }, [addStageOnBack]);

  const deleteStage = useCallback(
    (stageId: string) => {
      onDeleteStage({ parameterId: stageId });
    },
    [onDeleteStage]
  );

  useEffect(() => {
    if (!isLoading && isSuccess && stages?.length === 0) {
      addStage();
    }
  }, [addStage, isLoading, isSuccess, stages?.length]);

  return (
    <div className={s.ProjectSettings}>
      <Header />
      <ProjectBreadcrumbs />
      <div className={s.ProjectSettings__content}>
        <div className={s.ModelConfiguring__contentLeft}>
          <Heading
            className={s.ProjectSettings__headingH2}
            variant={HeadingVariant.H2}
          >
            Simulation
          </Heading>
          <LastSaved />
        </div>
        <div className={s.buttons}>
          <div className={s.loading}>{isMutating ? <LoadingIcon /> : null}</div>
          <Button
            disabled={prepared.length < 1}
            variant={ButtonVariant.ACTION}
            onClick={onExport}
          >
            Export
          </Button>
          <Button variant={ButtonVariant.ACTION} onClick={onRunSimulation}>
            Run simulation
          </Button>
        </div>
      </div>

      {!stages || stages.length === 0 || isLoading ? null : (
        <div className={s.stages}>
          {stages.map((stage, idx) => (
            <Stage
              isDeleteDisabled={stages.length === 1}
              data={prepared[idx]?.simRes ?? null}
              stageNumber={idx + 1}
              onAdd={() => addStage()}
              onDelete={() => deleteStage(stage.id)}
              initial={stage}
              initial_conditions={prepared[idx]?.initial_conditions ?? null}
              key={stage.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};
