import React, { useCallback, useEffect, useMemo, useState } from 'react';

import s from './project-settings.module.scss';
import { Header } from '../../components/Header/Header';
import { Heading, HeadingVariant } from '../../components';
import { Field } from '../../components/Field/Field';
import { Button, ButtonVariant } from '../../components/Button/Button';
import clsx from 'clsx';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import { Paths } from '../../routes/paths';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  useCreateProject,
  useProject as useQueryProject,
  useUpdateProject
} from '../../queries/projects';
import {
  FitParameter,
  FitProcedure,
  GetQueryProjectSettings,
  ProjectSettings
} from '../../api/projectSettings';
import {
  useCreateFitParameters,
  useCreateProjectSettings,
  useDeleteFitParameters,
  useProjectSettings,
  useUpdateProjectSettings
} from '../../queries/projectSettings';
import { FormFieldFloatTextValue } from './components/FormFieldFloatTextValue';
import { FormFieldRadio } from './components/FormFieldRadio';
import { FieldSpec, FormWrapper, useValidate } from './components/FormContext';
import { useIsCloneSelection } from '../../utils/useIsCloneSelection';

export interface RadioProps<Key> {
  value: string;
  onChange: (value: Key) => void;
  title: string;
  options: { value: Key; label: string }[];
}

export const Radio = <Key extends string>({
  title,
  options,
  value = options[0].value,
  onChange
}: RadioProps<Key>) => {
  return (
    <div className={s.Radio}>
      <div className={s.Radio__title}>{title}</div>
      <div className={s.Radio__options}>
        {options.map((opt) => {
          const isChecked = opt.value === value;
          return (
            <div
              className={s.Radio__option}
              onClick={() => onChange(opt.value)}
            >
              <div className={s.Radio__checkWrapper}>
                {' '}
                {isChecked ? <div className={s.Radio__check} /> : null}
              </div>
              <div className={s.Radio__optionText}>{opt.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const MaxMinField = ({ prefix, title }: { title?: string; prefix: string }) => {
  return (
    <div className={s.MaxMinField}>
      <div className={s.MaxMinField__title}>{title}</div>
      <div className={s.MaxMinField__items}>
        <div className={s.MaxMinField__item}>
          <div className={s.MaxMinField__itemInput}>
            <FormFieldFloatTextValue
              fieldName={`${prefix}_lower_bound`}
              size="small"
              className={s.MaxMinField__input}
            />
          </div>
          <div className={s.MaxMinField__itemPlaceholder}>Min</div>
        </div>
        <div className={s.MaxMinField__item}>
          <div className={s.MaxMinField__itemInput}>
            <FormFieldFloatTextValue
              fieldName={`${prefix}_upper_bound`}
              className={s.MaxMinField__input}
            />
          </div>
          <div className={s.MaxMinField__itemPlaceholder}>Max</div>
        </div>
      </div>
    </div>
  );
};

const StepsField = ({ prefix, title }: { prefix: string; title?: string }) => {
  return (
    <div className={s.MaxMinField}>
      <div className={s.MaxMinField__title}>{title}</div>
      <div className={s.MaxMinField__items}>
        <div className={s.MaxMinField__item}>
          <div className={s.MaxMinField__itemInput}>
            <FormFieldFloatTextValue
              fieldName={`${prefix}_step1`}
              size="small"
              className={s.MaxMinField__input}
            />
          </div>
          <div className={s.MaxMinField__itemPlaceholder}>Step 1</div>
        </div>
        <div className={s.MaxMinField__item}>
          <div className={s.MaxMinField__itemInput}>
            <FormFieldFloatTextValue
              fieldName={`${prefix}_step2`}
              size="small"
              className={s.MaxMinField__input}
            />
          </div>
          <div className={s.MaxMinField__itemPlaceholder}>Step 2</div>
        </div>
      </div>
    </div>
  );
};

const RadioWithInputs = <Key extends string>({
  title,
  options,
  withPlaceholder
}: {
  title?: string;
  options: { name: Key; title: string }[];
  withPlaceholder?: boolean;
}) => {
  return (
    <div className={clsx(s.Radio, s.RadioWithInputs)}>
      {title ? <div className={s.Radio__title}>{title}</div> : null}
      <div className={s.Radio__options}>
        {options.map(({ title, name }) => {
          return (
            <div className={s.Radio__option}>
              <div className={s.Radio__optionText}>{title}</div>
              {withPlaceholder && (
                <div className={s.Radio__optionPlaceholder} />
              )}
              <div className={s.Radio__optionInput}>
                <FormFieldFloatTextValue
                  fieldName={name}
                  className={s.Radio__input}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const modelConfiguringSteps = ['Data upload', 'Growth kinetics'];
const growthKineticsSteps = ['Growth kinetics'];
const simulationSteps = ['Growth kinetics'];

export const ProjectBreadcrumbs = () => {
  const { projectId = '' } = useParams<{ projectId?: string }>();
  const project = useQueryProject(projectId);
  const location = useLocation();

  const creatingStep = project?.data?.creating_step || '';

  const isCloneSelection = location.pathname.includes('clone-selection');
  const cloneSelectionSteps = useMemo(
    () => [
      {
        title: 'Growth kinetics fitting',
        active: location.pathname.includes('fitting'),
        passed: growthKineticsSteps.includes(creatingStep),
        to: Paths.CLONE_SELECTION_FITTING.replace(/:projectId/gi, projectId)
      },
      {
        title: 'Simulation',
        active: location.pathname.includes('simulation'),
        passed: simulationSteps.includes(creatingStep),
        to: Paths.CLONE_SELECTION_SIMULATION.replace(/:projectId/gi, projectId)
      }
    ],
    [creatingStep, location.pathname, projectId]
  );
  const digitalTwinSteps = useMemo(
    () => [
      {
        title: 'Additional configuration',
        active: location.pathname.includes('additional-configuration'),
        passed: modelConfiguringSteps.includes(creatingStep),
        to: Paths.DIGITAL_TWIN_ADDITIONAL_CONFIGURATION.replace(
          /:projectId/gi,
          projectId
        )
      },
      {
        title: 'Metabolite consumption',
        active:
          location.pathname.includes('metabolites') &&
          !location.pathname.includes('fitting-metabolites'),
        passed: modelConfiguringSteps.includes(creatingStep),
        to: Paths.DIGITAL_TWIN_METABOLITES.replace(/:projectId/gi, projectId)
      },
      {
        title: 'Growth kinetics fitting',
        active: location.pathname.includes('fitting-growth-kinetics'),
        // FIXME: fix it
        passed: growthKineticsSteps.includes(creatingStep),
        to: Paths.DIGITAL_TWIN_FITTING_GROWTH_KINETICS.replace(
          /:projectId/gi,
          projectId
        )
      },
      {
        title: 'Metabolite fitting',
        active: location.pathname.includes('fitting-metabolites'),
        // FIXME: fix it
        passed: growthKineticsSteps.includes(creatingStep),
        to: Paths.DIGITAL_TWIN_FITTING_METABOLITES.replace(
          /:projectId/gi,
          projectId
        )
      },
      {
        title: 'Titer fitting',
        active: location.pathname.includes('fitting-titer'),
        // FIXME: fix it
        passed: growthKineticsSteps.includes(creatingStep),
        to: Paths.DIGITAL_TWIN_FITTING_TITER.replace(/:projectId/gi, projectId)
      },
      {
        title: 'Simulation',
        active: location.pathname.includes('simulation'),
        // FIXME: fix it
        passed: growthKineticsSteps.includes(creatingStep),
        to: Paths.DIGITAL_TWIN_SIMULATION.replace(/:projectId/gi, projectId)
      }
    ],
    [creatingStep, location.pathname, projectId]
  );
  const commonSteps = useMemo(
    () => [
      {
        title: 'Data Upload',
        active: location.pathname.includes('create'),
        passed: true,
        to: isCloneSelection
          ? projectId
            ? Paths.CLONE_SELECTION_PROJECT_SETTINGS_EDIT.replace(
                /:projectId/gi,
                projectId
              )
            : Paths.CLONE_SELECTION_PROJECT_SETTINGS
          : projectId
          ? Paths.DIGITAL_TWIN_PROJECT_SETTINGS_EDIT.replace(
              /:projectId/gi,
              projectId
            )
          : Paths.DIGITAL_TWIN_PROJECT_SETTINGS
      },
      {
        title: 'Model configuring',
        active: location.pathname.includes('model-configuration'),
        passed: modelConfiguringSteps.includes(creatingStep),
        to: (isCloneSelection
          ? Paths.CLONE_SELECTION_MODEL_CONFIGURATION
          : Paths.DIGITAL_TWIN_MODEL_CONFIGURATION
        ).replace(/:projectId/gi, projectId)
      }
    ],
    [creatingStep, isCloneSelection, location.pathname, projectId]
  );
  const steps = useMemo(() => {
    return commonSteps.concat(
      isCloneSelection ? cloneSelectionSteps : digitalTwinSteps
    );
  }, [cloneSelectionSteps, commonSteps, digitalTwinSteps, isCloneSelection]);
  return (
    <Breadcrumbs
      steps={steps}
      title={
        <div className={s.ProjectSettings__title}>
          <div className={s.ProjectSettings__titleMain}>
            {project?.data?.name || 'Project creation'}
          </div>
        </div>
      }
    />
  );
};

type ComponentFitParameters = {
  primary_growth_rate_upper_bound: number;
  primary_growth_rate_lower_bound: number;
  primary_depth_rate_upper_bound: number;
  primary_depth_rate_lower_bound: number;
  toxicity_rate_upper_bound: number;
  toxicity_rate_lower_bound: number;
  lysing_rate_upper_bound: number;
  lysing_rate_lower_bound: number;
  biomaterial_inhibition_upper_bound: number;
  biomaterial_inhibition_lower_bound: number;
};
const mapComponentFitParametersToQueryFitParametersToUpdate = (
  componentFitParameters: Fields,
  prevFitParameters: (FitParameter & { id: string })[]
): (FitParameter & { id: string })[] => {
  return prevFitParameters.map((fitParam) => {
    if (
      fitParam.name === 'Bio material' &&
      componentFitParameters.source_of_inhibition === 'BIOMATERIAL'
    ) {
      return {
        id: fitParam.id,
        name: 'Bio material',
        role: 'BUILT_IN',
        type: 'INHIBITOR_FACTOR',
        threshold: {
          lower_bound:
            componentFitParameters.biomaterial_inhibition_lower_bound,
          upper_bound: componentFitParameters.biomaterial_inhibition_upper_bound
        }
      };
    }

    if (
      componentFitParameters.source_of_inhibition === 'BIOMATERIAL' &&
      !prevFitParameters.find((el) => el.name === 'Bio material') &&
      fitParam.name === 'Lysed cells'
    ) {
      return {
        id: fitParam.id,
        name: 'Bio material',
        role: 'BUILT_IN',
        type: 'INHIBITOR_FACTOR',
        threshold: {
          lower_bound:
            componentFitParameters.biomaterial_inhibition_lower_bound,
          upper_bound: componentFitParameters.biomaterial_inhibition_upper_bound
        }
      };
    }
    switch (fitParam.name) {
      case 'Primary growth rate': {
        return {
          id: fitParam.id,
          name: 'Primary growth rate',
          role: 'GROWTH_PARAMETER',
          lower_bound: componentFitParameters.primary_growth_rate_lower_bound,
          upper_bound: componentFitParameters.primary_growth_rate_upper_bound
        };
      }
      case 'Primary death rate': {
        return {
          id: fitParam.id,
          name: 'Primary death rate',
          role: 'GROWTH_PARAMETER',
          lower_bound: componentFitParameters.primary_depth_rate_lower_bound,
          upper_bound: componentFitParameters.primary_depth_rate_upper_bound
        };
      }
      case 'Toxicity rate': {
        return {
          id: fitParam.id,
          name: 'Toxicity rate',
          role: 'GROWTH_PARAMETER',
          upper_bound: componentFitParameters.toxicity_rate_upper_bound,
          lower_bound: componentFitParameters.toxicity_rate_lower_bound
        };
      }
      case 'Lysing rate': {
        return {
          id: fitParam.id,
          name: 'Lysing rate',
          role: 'GROWTH_PARAMETER',
          lower_bound: componentFitParameters.lysing_rate_lower_bound,
          upper_bound: componentFitParameters.lysing_rate_upper_bound
        };
      }
    }
    return fitParam;
  });
};
const mapComponentFitParametersToQueryFitParameters = (
  componentFitParameters: {
    source_of_inhibition: string;
  } & ComponentFitParameters
): FitParameter[] => {
  const arr = [
    {
      name: 'Primary growth rate',
      role: 'GROWTH_PARAMETER',
      lower_bound: componentFitParameters.primary_growth_rate_lower_bound,
      upper_bound: componentFitParameters.primary_growth_rate_upper_bound
    },
    {
      name: 'Primary death rate',
      role: 'GROWTH_PARAMETER',
      lower_bound: componentFitParameters.primary_depth_rate_lower_bound,
      upper_bound: componentFitParameters.primary_depth_rate_upper_bound
    },
    {
      name: 'Toxicity rate',
      role: 'GROWTH_PARAMETER',
      upper_bound: componentFitParameters.toxicity_rate_upper_bound,
      lower_bound: componentFitParameters.toxicity_rate_lower_bound
    },
    {
      name: 'Lysing rate',
      role: 'GROWTH_PARAMETER',
      lower_bound: componentFitParameters.lysing_rate_lower_bound,
      upper_bound: componentFitParameters.lysing_rate_upper_bound
    },
    {
      name: 'Bio material',
      role: 'BUILT_IN',
      type:
        componentFitParameters.source_of_inhibition !== 'LYSED_CELLS'
          ? 'INHIBITOR_FACTOR'
          : undefined,
      threshold:
        componentFitParameters.source_of_inhibition !== 'LYSED_CELLS'
          ? {
              lower_bound:
                componentFitParameters.biomaterial_inhibition_lower_bound,
              upper_bound:
                componentFitParameters.biomaterial_inhibition_upper_bound
            }
          : undefined
    }
  ];

  if (componentFitParameters.source_of_inhibition === 'LYSED_CELLS') {
    arr.push({
      name: 'Lysed cells',
      role: 'BUILT_IN',
      type: 'INHIBITOR_FACTOR',
      threshold: {
        lower_bound: componentFitParameters.biomaterial_inhibition_lower_bound,
        upper_bound: componentFitParameters.biomaterial_inhibition_upper_bound
      }
    });
  }

  return arr as any;
};

const initialFitParameters = {
  primary_growth_rate_lower_bound: 0.05,
  primary_growth_rate_upper_bound: 1.2,

  primary_depth_rate_lower_bound: 0.001,
  primary_depth_rate_upper_bound: 0.8,

  toxicity_rate_lower_bound: 0.000001,
  toxicity_rate_upper_bound: 0.1,

  lysing_rate_lower_bound: 0.1,
  lysing_rate_upper_bound: 2,

  biomaterial_inhibition_lower_bound: 10,
  biomaterial_inhibition_upper_bound: 250
};

export const mapGetQueryDateTypeToComponentDateType = {
  Days: 'DAYS',
  Hours: 'HOURS'
} as const;
const mapGetQueryObjectivePenaltyToComponentObjectivePenalty = {
  SQUARED: 'SQUARED_ERROR',
  SSE: 'AUTO_CORRELATION'
} as const;
export const mapGetQueryViableCellDensityTypeToComponentViableCellDensityType =
  {
    'cells/ml': 'NORMAL',
    '1e6 cells/ml': 'E_SIX',
    '1e5 cells/ml': 'E_FIVE'
  } as const;

const mapGetQuerySourceToComponentSource = {
  'Lysed cells': 'LYSED_CELLS',
  Biomaterial: 'BIOMATERIAL'
} as const;

export const mapQueryProjectSettingsToComponentProjectSettings = ({
  objective_penalty,
  date_type,
  viable_cell_density_type,
  source_of_toxicity,
  source_of_inhibition,
  ...projectSettings
}: GetQueryProjectSettings): ProjectSettings => ({
  ...projectSettings,
  objective_penalty:
    mapGetQueryObjectivePenaltyToComponentObjectivePenalty[objective_penalty],
  date_type: mapGetQueryDateTypeToComponentDateType[date_type],
  viable_cell_density_type:
    mapGetQueryViableCellDensityTypeToComponentViableCellDensityType[
      viable_cell_density_type
    ],
  source_of_toxicity: mapGetQuerySourceToComponentSource[source_of_toxicity],
  source_of_inhibition: mapGetQuerySourceToComponentSource[source_of_inhibition]
});
const mapQueryFitParametersToComponentFitParameters = (
  queryFitParameters: FitParameter[]
): ComponentFitParameters => {
  const params: ComponentFitParameters = { ...initialFitParameters };
  queryFitParameters.forEach((fitParameter) => {
    switch (fitParameter.name) {
      case 'Primary growth rate': {
        params.primary_growth_rate_lower_bound =
          fitParameter.lower_bound || params.primary_growth_rate_lower_bound;
        params.primary_growth_rate_upper_bound =
          fitParameter.upper_bound || params.primary_growth_rate_upper_bound;
        break;
      }

      case 'Primary death rate': {
        params.primary_depth_rate_lower_bound =
          fitParameter.lower_bound || params.primary_depth_rate_lower_bound;
        params.primary_depth_rate_upper_bound =
          fitParameter.upper_bound || params.primary_depth_rate_upper_bound;
        break;
      }
      case 'Toxicity rate': {
        params.toxicity_rate_lower_bound =
          fitParameter.lower_bound || params.toxicity_rate_lower_bound;
        params.toxicity_rate_upper_bound =
          fitParameter.upper_bound || params.toxicity_rate_upper_bound;
        break;
      }
      case 'Lysing rate': {
        params.lysing_rate_lower_bound =
          fitParameter.lower_bound || params.lysing_rate_lower_bound;
        params.lysing_rate_upper_bound =
          fitParameter.upper_bound || params.lysing_rate_upper_bound;

        break;
      }
      case 'Lysed cells': {
        params.biomaterial_inhibition_lower_bound =
          fitParameter.threshold?.lower_bound ||
          params.biomaterial_inhibition_lower_bound;
        params.biomaterial_inhibition_upper_bound =
          fitParameter.threshold?.upper_bound ||
          params.biomaterial_inhibition_upper_bound;
        break;
      }
      case 'Bio material': {
        params.biomaterial_inhibition_lower_bound =
          fitParameter.threshold?.lower_bound ||
          params.biomaterial_inhibition_lower_bound;
        params.biomaterial_inhibition_upper_bound =
          fitParameter.threshold?.upper_bound ||
          params.biomaterial_inhibition_upper_bound;
        break;
      }
    }
  });
  return params;
};
const initialProjectSettings: ProjectSettings = {
  fed_batch_call_density: 2,
  fed_batch_viability: 1,
  perfusion_bleed: 2,
  perfusion_viability: 1,
  objective_penalty: 'AUTO_CORRELATION',
  date_type: 'DAYS',
  viable_cell_density_type: 'E_SIX',
  bleed_controller_gain: 0.02,
  bleed_controller_rest_time: 2,
  max_oxygen_rate: 25,
  perfusion_feed_rate_threshold: 0.2,
  source_of_toxicity: 'LYSED_CELLS',
  source_of_inhibition: 'BIOMATERIAL',
  render_uptime: ''
};
type ComponentFitProcedures = {
  number_of_particles_step1: number;
  iterations_step1: number;

  number_of_particles_step2: number;
  iterations_step2: number;
};
const initialComponentFitProcedures = {
  number_of_particles_step1: 75,
  iterations_step1: 25,

  number_of_particles_step2: 75,
  iterations_step2: 25
};
const mapComponentFitProceduresToQueryFitProceduresToUpdate = (
  componentFitProcedures: ComponentFitProcedures,
  prevFitProcedures: (FitProcedure & { id: string })[]
): (FitProcedure & { id: string })[] => {
  return prevFitProcedures.map((prevFitProcedure) => {
    switch (prevFitProcedure.step_name) {
      case 'Exploration': {
        return {
          id: prevFitProcedure.id,
          step_name: 'Exploration',
          number_of_particles: componentFitProcedures.number_of_particles_step1,
          iterations: componentFitProcedures.iterations_step1,
          speed: 0.8
        };
      }
      case 'Refinement': {
        return {
          id: prevFitProcedure.id,
          step_name: 'Refinement',
          number_of_particles: componentFitProcedures.number_of_particles_step2,
          iterations: componentFitProcedures.iterations_step2,
          speed: 0.2
        };
      }
    }
    return prevFitProcedure;
  });
};

const mapComponentFitProceduresToQueryFitProcedures = (
  componentFitProcedures: ComponentFitProcedures
): FitProcedure[] => {
  return [
    {
      step_name: 'Exploration',
      number_of_particles: componentFitProcedures.number_of_particles_step1,
      iterations: componentFitProcedures.iterations_step1,
      speed: 0.8
    },
    {
      step_name: 'Refinement',
      number_of_particles: componentFitProcedures.number_of_particles_step2,
      iterations: componentFitProcedures.iterations_step2,
      speed: 0.2
    }
  ];
};

const mapQueryFitProceduresToComponentFitProcedures = (
  queryFitProcedures: FitProcedure[]
): ComponentFitProcedures => {
  const componentFitProcedures: ComponentFitProcedures = {
    ...initialComponentFitProcedures
  };
  queryFitProcedures.forEach((fitProcedure) => {
    const value = {
      number_of_particles: fitProcedure.number_of_particles,
      iterations: fitProcedure.iterations
    };
    if (fitProcedure.step_name === 'Refinement') {
      componentFitProcedures.number_of_particles_step2 =
        value.number_of_particles;
      componentFitProcedures.iterations_step2 = value.iterations;
    } else if (fitProcedure.step_name === 'Exploration') {
      componentFitProcedures.number_of_particles_step1 =
        value.number_of_particles;
      componentFitProcedures.iterations_step1 = value.iterations;
    }
  });
  return componentFitProcedures;
};

const ActionsButtons = ({
  disabled,
  handleSave,
  isOpenAdvanced,
  onOpenAdvanced
}: {
  disabled?: boolean;
  handleSave: (isContinue?: boolean) => void;
  isOpenAdvanced: boolean;
  onOpenAdvanced: () => void;
}) => {
  const validate = useValidate();
  return (
    <div className={s.ProjectSettings__actionButtons}>
      <Button
        hoverVariant={ButtonVariant.ACTION}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          if (Object.keys(validate()).length === 0) {
            handleSave(false);
          }
        }}
      >
        Save
      </Button>
      <Button
        hoverVariant={ButtonVariant.ACTION}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          if (Object.keys(validate()).length === 0) {
            handleSave(true);
          }
        }}
      >
        Save & Continue
      </Button>
      <div
        className={s.ProjectSettings__actionButtonAdvanced}
        onClick={onOpenAdvanced}
      >
        {isOpenAdvanced ? 'Close' : 'View'} advanced settings
      </div>
    </div>
  );
};

const initialFields = {
  ...initialProjectSettings,
  ...initialFitParameters,
  ...initialComponentFitProcedures
};

type Fields = ComponentFitProcedures & ProjectSettings & ComponentFitParameters;

const mapFieldsToProjectSettings = (fields: Fields): ProjectSettings => {
  return {
    fed_batch_call_density: fields.fed_batch_call_density,
    fed_batch_viability: fields.fed_batch_viability,
    perfusion_bleed: fields.perfusion_bleed,
    perfusion_viability: fields.perfusion_viability,
    objective_penalty: fields.objective_penalty,
    date_type: fields.date_type,
    viable_cell_density_type: fields.viable_cell_density_type,
    bleed_controller_gain: fields.bleed_controller_gain,
    bleed_controller_rest_time: fields.bleed_controller_rest_time,
    max_oxygen_rate: fields.max_oxygen_rate,
    perfusion_feed_rate_threshold: fields.perfusion_feed_rate_threshold,
    source_of_toxicity: fields.source_of_toxicity,
    source_of_inhibition: fields.source_of_inhibition
  };
};
export const ProjectSettingsPage = () => {
  const { projectId } = useParams<{ projectId?: string }>();
  const history = useHistory();
  const project = useQueryProject(projectId);
  const { mutateAsync: mutateAsyncFitParams } = useCreateFitParameters(
    projectId as string
  );
  const { mutateAsync: deleteAsyncFitParams } = useDeleteFitParameters(
    projectId as string
  );
  const projectParameters = useProjectSettings(projectId);
  const isEdit = Boolean(projectId && project);
  const [isOpenAdvanced, setOpenAdvanced] = useState(false);
  const [name, setName] = useState('');
  const [initialFile, setInitialFile] = useState<File | null>(null);
  const [fields, setFields] = useState<Partial<Fields>>({});
  const isCloneSelection = useIsCloneSelection();
  useEffect(() => {
    if (name === '' && project.data?.name) {
      setName(project.data.name);
    }
  }, [name, project]);
  const {
    mutateAsync: mutateAsyncCreateProject,
    isLoading: isCreateProjectLoading
  } = useCreateProject();
  const {
    mutateAsync: mutateAsyncUpdateProject,
    isLoading: isUpdateProjectLoading
  } = useUpdateProject();
  const {
    mutateAsync: mutateAsyncCreateProjectSettings,
    isLoading: isCreateProjectSettingsLoading
  } = useCreateProjectSettings();
  const {
    mutateAsync: mutateAsyncUpdateProjectSettings,
    isLoading: isUpdateProjectSettingsLoading
  } = useUpdateProjectSettings();
  const isSomethingLoading =
    isCreateProjectLoading ||
    isUpdateProjectLoading ||
    isCreateProjectSettingsLoading ||
    isUpdateProjectSettingsLoading;

  useEffect(() => {
    let newFields: Partial<Fields> = {};
    if (!projectParameters.isLoading && projectParameters.data) {
      if (projectParameters.data.fitProcedures.data) {
        newFields = {
          ...newFields,
          ...mapQueryFitProceduresToComponentFitProcedures(
            projectParameters.data.fitProcedures.data
          )
        };
      }
      if (projectParameters.data.fitParameters.data) {
        newFields = {
          ...newFields,
          ...mapQueryFitParametersToComponentFitParameters(
            projectParameters.data.fitParameters.data
          )
        };
      }
      if (projectParameters.data.projectSettings.data) {
        newFields = {
          ...newFields,
          ...mapQueryProjectSettingsToComponentProjectSettings(
            projectParameters.data.projectSettings.data
          )
        };
      }
      setFields(newFields);
    }
  }, [projectParameters.data, projectParameters.isLoading]);
  const handleUpdate = useCallback(async () => {
    let fitPatametersData = projectParameters.data!.fitParameters.data;
    if (fitPatametersData.find((el) => el.name === 'Bio material')) {
      if (
        fields.source_of_inhibition === 'BIOMATERIAL' &&
        fitPatametersData.find((el) => el.name === 'Lysed cells')
      ) {
        await deleteAsyncFitParams(
          fitPatametersData.find((el) => el.name === 'Lysed cells')
            ?.id as string
        );

        fitPatametersData = fitPatametersData.filter(
          (el) => el.name !== 'Lysed cells'
        );
      }

      if (fields.source_of_inhibition === 'LYSED_CELLS') {
        await deleteAsyncFitParams(
          fitPatametersData.find((el) => el.name === 'Bio material')
            ?.id as string
        );

        fitPatametersData = fitPatametersData.filter(
          (el) => el.name !== 'Bio material'
        );

        const payloadForFitMutateParams: any[] = [
          {
            name: 'Bio material',
            role: 'BUILT_IN'
          }
        ];

        if (!fitPatametersData.find((el) => el.name === 'Lysed cells')) {
          payloadForFitMutateParams.push({
            name: 'Lysed cells',
            role: 'BUILT_IN',
            type: 'INHIBITOR_FACTOR',
            threshold: {
              lower_bound: fields.biomaterial_inhibition_lower_bound as number,
              upper_bound: fields.biomaterial_inhibition_upper_bound as number
            }
          });
        }

        await mutateAsyncFitParams(payloadForFitMutateParams);
      }
    }
    await Promise.all([
      mutateAsyncUpdateProject({
        projectId: projectId as string,
        initialFile: initialFile ?? undefined,
        name
      }),

      mutateAsyncUpdateProjectSettings({
        projectId: projectId as string,
        fitParameters: mapComponentFitParametersToQueryFitParametersToUpdate(
          fields as Fields,
          fitPatametersData
        ),
        fitProcedures: mapComponentFitProceduresToQueryFitProceduresToUpdate(
          fields as Fields,
          projectParameters.data!.fitProcedures.data
        ),
        projectSettings: {
          id: projectParameters.data!.projectSettings.data.id,
          ...mapFieldsToProjectSettings(fields as Fields)
        }
      })
    ]);
    return projectId as string;
  }, [
    fields,
    initialFile,
    mutateAsyncUpdateProject,
    mutateAsyncUpdateProjectSettings,
    name,
    projectId,
    projectParameters.data,
    deleteAsyncFitParams,
    mutateAsyncFitParams
  ]);
  const sendComplex = useCallback(async () => {
    const createProjectResult = await mutateAsyncCreateProject({
      initialFile: initialFile as File,
      name,
      type: 'SEED_TRAIN'
    });
    await mutateAsyncCreateProjectSettings({
      projectId: createProjectResult.data.id,
      projectSettings: mapFieldsToProjectSettings(fields as Fields),
      fitProcedures: mapComponentFitProceduresToQueryFitProcedures(
        fields as Fields
      ),
      fitParameters: mapComponentFitParametersToQueryFitParameters(
        fields as Fields
      )
    });

    history.push(
      `${
        isCloneSelection
          ? Paths.CLONE_SELECTION_PROJECT_SETTINGS_EDIT
          : Paths.DIGITAL_TWIN_PROJECT_SETTINGS_EDIT
      }`.replace(/:projectId/gi, createProjectResult.data.id)
    );
    return createProjectResult.data.id;
  }, [
    isCloneSelection,
    history,
    fields,
    initialFile,
    mutateAsyncCreateProject,
    mutateAsyncCreateProjectSettings,
    name
  ]);

  const handleSave = useCallback(
    async (isContinue) => {
      let projectId;
      if (isEdit) {
        projectId = await handleUpdate();
      } else {
        projectId = await sendComplex();
      }
      if (isContinue) {
        history.push(
          (isCloneSelection
            ? Paths.CLONE_SELECTION_MODEL_CONFIGURATION
            : Paths.DIGITAL_TWIN_MODEL_CONFIGURATION
          ).replace(/:projectId/, projectId)
        );
      }
    },
    [handleUpdate, history, isCloneSelection, isEdit, sendComplex]
  );

  const fieldsSpec = useMemo(
    () =>
      [
        {
          name: 'date_type',
          title: 'Time',
          type: 'radio',
          required: true,
          options: [
            { label: 'Days', value: 'DAYS' },
            { label: 'Hours', value: 'HOURS' }
          ]
        },
        {
          name: 'viable_cell_density_type',
          title: 'Viable cell density',
          type: 'radio',
          required: true,
          options: [
            { label: '1e6 cells/ml', value: 'E_SIX' },
            { label: '1e5 cells/ml', value: 'E_FIVE' },
            { label: 'cells/ml', value: 'NORMAL' }
          ]
        },
        {
          name: 'primary_growth_rate_upper_bound',
          type: 'float',
          required: true,
          min: Math.max(fields.primary_growth_rate_lower_bound || 1e-3, 1e-3),
          max: 5,
          customError:
            fields.primary_growth_rate_lower_bound || 1e-3 > 1e-3
              ? 'Max cannot be less then min'
              : null
        },
        {
          name: 'primary_growth_rate_lower_bound',
          type: 'float',
          required: true,
          min: 1e-3,
          max: 5
        },
        {
          name: 'primary_depth_rate_upper_bound',
          type: 'float',
          required: true,
          min: Math.max(fields.primary_depth_rate_lower_bound || 1e-5, 1e-5),
          max: 2,
          customError:
            fields.primary_depth_rate_lower_bound || 1e-5 > 1e-5
              ? 'Max cannot be less then min'
              : null
        },
        {
          name: 'primary_depth_rate_lower_bound',
          type: 'float',
          required: true,
          min: 1e-5,
          max: 2
        },
        {
          name: 'toxicity_rate_upper_bound',
          type: 'float',
          required: true,
          min: Math.max(fields.toxicity_rate_lower_bound || 1e-8, 1e-8),
          max: 0.5,
          customError:
            fields.toxicity_rate_lower_bound || 1e-8 > 1e-8
              ? 'Max cannot be less then min'
              : null
        },
        {
          name: 'toxicity_rate_lower_bound',
          type: 'float',
          required: true,
          min: 1e-8,
          max: 0.5
        },
        {
          name: 'lysing_rate_upper_bound',
          type: 'float',
          required: true,
          min: Math.max(fields.lysing_rate_lower_bound || 0.1, 0.1),
          max: 5,
          customError:
            fields.lysing_rate_lower_bound || 0.1 > 0.1
              ? 'Max cannot be less then min'
              : null
        },
        {
          name: 'lysing_rate_lower_bound',
          type: 'float',
          required: true,
          min: 0.1,
          max: 5
        },
        {
          name: 'biomaterial_inhibition_upper_bound',
          type: 'float',
          required: true,
          min: Math.max(fields.biomaterial_inhibition_lower_bound || 1, 1),
          max: 500,
          customError:
            fields.biomaterial_inhibition_lower_bound || 1 > 1
              ? 'Max cannot be less then min'
              : null
        },
        {
          name: 'biomaterial_inhibition_lower_bound',
          type: 'float',
          required: true,
          min: 1,
          max: 500
        },
        {
          name: 'number_of_particles_step1',
          type: 'int',
          required: true,
          min: 10,
          max: 500
        },
        {
          name: 'number_of_particles_step2',
          type: 'int',
          required: true,
          min: 10,
          max: 500
        },
        {
          name: 'iterations_step1',
          type: 'int',
          required: true,
          min: 10,
          max: 500
        },
        {
          name: 'iterations_step2',
          type: 'int',
          required: true,
          min: 10,
          max: 500
        },
        {
          name: 'objective_penalty',
          title: 'objective penalty',
          required: true,
          type: 'radio',
          options: [
            { value: 'SQUARED_ERROR', label: 'squared error' },
            { value: 'AUTO_CORRELATION', label: 'auto-correlation' }
          ]
        },
        {
          name: 'fed_batch_call_density',
          type: 'float',
          required: true,
          min: 0,
          max: 10
        },
        {
          name: 'fed_batch_viability',
          type: 'float',
          required: true,
          min: 0,
          max: 10
        },
        {
          name: 'perfusion_bleed',
          type: 'float',
          required: true,
          min: 0,
          max: 10
        },
        {
          name: 'perfusion_viability',
          type: 'float',
          required: true,
          min: 0,
          max: 10
        },
        {
          name: 'bleed_controller_gain',
          type: 'float',
          required: true,
          min: 1e-5,
          max: 0.5
        },
        {
          name: 'bleed_controller_rest_time',
          type: 'float',
          required: true,
          min: 0.2,
          max: 5
        },
        {
          name: 'max_oxygen_rate',
          type: 'float',
          required: true,
          min: 10,
          max: 100
        },
        {
          name: 'perfusion_feed_rate_threshold',
          type: 'float',
          required: true,
          min: 0,
          max: 2
        },
        {
          title: 'Source of toxicity',
          name: 'source_of_toxicity',
          required: true,
          type: 'radio',
          options: [
            { value: 'LYSED_CELLS', label: 'Lysed cells' },
            { value: 'BIOMATERIAL', label: 'Biomaterial' }
          ]
        },
        {
          title: 'Source of inhibition',
          name: 'source_of_inhibition',
          required: true,
          type: 'radio',
          options: [
            { value: 'LYSED_CELLS', label: 'Lysed cells' },
            { value: 'BIOMATERIAL', label: 'Biomaterial' }
          ]
        }
      ] as FieldSpec[],
    [
      fields.biomaterial_inhibition_lower_bound,
      fields.lysing_rate_lower_bound,
      fields.primary_depth_rate_lower_bound,
      fields.primary_growth_rate_lower_bound,
      fields.toxicity_rate_lower_bound
    ]
  );

  return (
    <div className={s.ProjectSettings}>
      <FormWrapper
        defaultValues={initialFields}
        fields={fields}
        setFields={setFields}
        fieldsSpec={fieldsSpec}
      >
        <Header />
        <ProjectBreadcrumbs />
        <div className={s.ProjectSettings__content}>
          <Heading
            className={s.ProjectSettings__headingH2}
            variant={HeadingVariant.H2}
          >
            Project Settings
          </Heading>

          <div className={s.ProjectSettings__shortInfo}>
            <Field
              className={s.ProjectSettings__shortInfoItem}
              label="Project name"
              size="large"
              value={name}
              onChange={setName}
            />

            <Field
              value={isEdit ? project.data?.initial_data : undefined}
              onUpload={setInitialFile}
              label="Upload data file"
              type="file"
              size="large"
              accept=".xls,.xlsx,.csv"
            />
          </div>
          {isOpenAdvanced && (
            <div>
              <Heading
                className={s.ProjectSettings__heading}
                variant={HeadingVariant.H3}
              >
                Kinetic limits
              </Heading>
              <div className={s.ProjectSettings__kineticLimits}>
                <MaxMinField
                  prefix="primary_growth_rate"
                  title="max growth rate limit"
                />
                <MaxMinField prefix="primary_depth_rate" title="death rate" />
                <MaxMinField prefix="toxicity_rate" title="toxicity" />
                <MaxMinField prefix="lysing_rate" title="lysing rate" />
                <MaxMinField
                  prefix="biomaterial_inhibition"
                  title={`${
                    fields.source_of_inhibition === 'LYSED_CELLS'
                      ? 'lysed cell'
                      : 'biomaterial'
                  }  inhibition`}
                />
              </div>
              <Heading
                className={s.ProjectSettings__heading}
                variant={HeadingVariant.H3}
              >
                Solver
              </Heading>
              <div className={s.ProjectSettings__solverFirstRow}>
                <StepsField
                  prefix="number_of_particles"
                  title="number of particles"
                />
                <StepsField prefix="iterations" title="number of iterations" />
              </div>
              <div className={s.ProjectSettings__solverSecondRow}>
                <FormFieldRadio fieldName="objective_penalty" />
                <RadioWithInputs
                  withPlaceholder
                  title="weighting (fed-batch)"
                  options={[
                    {
                      name: 'fed_batch_call_density',
                      title: 'viable cell density'
                    },
                    { name: 'fed_batch_viability', title: 'viability' }
                  ]}
                />
                <RadioWithInputs
                  withPlaceholder
                  title="weighting (perfusion)"
                  options={[
                    { name: 'perfusion_bleed', title: 'bleed' },
                    { name: 'perfusion_viability', title: 'viability' }
                  ]}
                />
              </div>
              <div className={s.ProjectSettings__row}>
                <div>
                  <Heading
                    className={s.ProjectSettings__heading}
                    variant={HeadingVariant.H3}
                  >
                    Bleed control
                  </Heading>
                  <div
                    className={clsx(
                      s.ProjectSettings__inputParameter,
                      s.ProjectSettings__inputParams__wideInput
                    )}
                  >
                    <RadioWithInputs
                      withPlaceholder
                      options={[
                        {
                          name: 'bleed_controller_gain',
                          title: 'Bleed control gain'
                        }
                      ]}
                    />
                    <RadioWithInputs
                      withPlaceholder
                      options={[
                        {
                          name: 'bleed_controller_rest_time',
                          title: 'Bleed control reset time'
                        }
                      ]}
                    />
                  </div>
                </div>
                <div>
                  <Heading
                    className={s.ProjectSettings__heading}
                    variant={HeadingVariant.H3}
                  >
                    Physical limitations
                  </Heading>
                  <div
                    className={clsx(
                      s.ProjectSettings__inputParameter,
                      s.ProjectSettings__inputParams__wideInput
                    )}
                  >
                    <RadioWithInputs
                      withPlaceholder
                      options={[
                        {
                          name: 'max_oxygen_rate',
                          title: 'Max oxygen delivery time'
                        }
                      ]}
                    />
                  </div>
                </div>

                <div />
                <div>
                  <Heading
                    className={s.ProjectSettings__heading}
                    variant={HeadingVariant.H3}
                  >
                    Inhibition and toxicity
                  </Heading>
                  <div className={s.ProjectSettings__inhibitionAndToxicity}>
                    <FormFieldRadio fieldName="source_of_toxicity" />
                    <FormFieldRadio fieldName="source_of_inhibition" />
                  </div>
                </div>
                <div>
                  <Heading
                    className={s.ProjectSettings__heading}
                    variant={HeadingVariant.H3}
                  >
                    General parameters
                  </Heading>
                  <div className={s.ProjectSettings__inputParameter}>
                    <RadioWithInputs
                      withPlaceholder
                      options={[
                        {
                          name: 'perfusion_feed_rate_threshold',
                          title: 'Perfusion feed rate threshold'
                        }
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <ActionsButtons
            handleSave={handleSave}
            disabled={!name || (!isEdit && !initialFile) || isSomethingLoading}
            isOpenAdvanced={isOpenAdvanced}
            onOpenAdvanced={() => setOpenAdvanced((s) => !s)}
          />
        </div>
      </FormWrapper>
    </div>
  );
};
