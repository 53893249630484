import {
  FormContext,
  useField
} from '../../../../../../project-settings/components/FormContext';
import { MultiSelect } from '../../../../../../../components/MultiSelect/MultiSelect';
import React, { useContext, useMemo, useState } from 'react';
import { FloatTextInput } from '../../../../../../../components/FloatInput/FloatInput';

enum FieldName {
  FEED = 'feed',
  FEED_TYPE = 'feed-type'
}

export const SelectOrFloatField = ({
  fieldName,
  disabled
}: {
  fieldName: string;
  disabled?: boolean;
}) => {
  const field = useField(fieldName);
  const ctx = useContext(FormContext);

  const showFloatTextInput = useMemo(() => {
    if (typeof field.value !== 'number') {
      return true;
    }
    return !field.options?.find(
      (option) => option.value.toString() === field.value.toString()
    );
  }, [field.options, field.value]);
  const [isCustom, setCustom] = useState(showFloatTextInput);
  return (
    <div>
      <MultiSelect
        onlyOne
        value={useMemo(() => {
          if (isCustom) {
            return ['custom'];
          }
          return [field.value];
        }, [isCustom, field.value])}
        onChange={(value) => {
          if (value[0] === 'custom') {
            setCustom(true);
            return;
          }
          setCustom(false);
          field.onChange(value[0]);
          if (
            fieldName === FieldName.FEED &&
            ctx.fieldsSpec.find((v) => v.name === FieldName.FEED_TYPE)
          ) {
            ctx.onChangeField(FieldName.FEED_TYPE, value[0]);
          }
        }}
        options={[...field.options, { label: 'Custom', value: 'custom' }]}
        error={isCustom ? undefined : field.error}
        disabled={fieldName === FieldName.FEED_TYPE || disabled}
      />
      <div style={{ height: 10 }} />
      {isCustom && (
        <FloatTextInput
          value={field.value}
          onChange={field.onChange}
          error={isCustom ? field.error : undefined}
        />
      )}
    </div>
  );
};
