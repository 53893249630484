import React, { useContext } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import s from './Header.module.scss';
import { BaseComponent } from '../../utils/BaseComponent';
import { Logo } from '../Logo/Logo';
import user from './assets/user.svg';
import { Paths } from '../../routes/paths';
import { useUserLogout } from '../../queries/users';
import { AdminCtx } from '../AuthGuard/AuthGuard';
import { Button } from '../Button/Button';

export interface HeaderProps extends BaseComponent {}

export const Header: React.FC<HeaderProps> = ({ className }) => {
  const history = useHistory();
  const { mutate: logout } = useUserLogout();
  const isAdmin = useContext(AdminCtx);
  return (
    <div className={clsx(className, s.Header)}>
      <div
        className={s.Header__left}
        onClick={() => history.push(Paths.PROJECTS)}
      >
        <Logo className={s.Header__logo} />
        <div className={s.Header__title}>Seed Train</div>
      </div>
      <div className={s.Header__right} onClick={() => logout()}>
        {isAdmin && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              history.push('/admin');
            }}
            size="small"
            className={s.Header__admin}
          >
            Admin
          </Button>
        )}
        <div className={s.Header__userIcon}>
          <img alt="user" src={user} />
        </div>
        <div>Logout</div>
      </div>
    </div>
  );
};
