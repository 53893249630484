export enum MUTATIONS {
  USER = 'USER',
  USER_LOGOUT = 'USER_LOGOUT',
  DELETE_PROJECT = 'DELETE_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  CREATE_PROJECT = 'CREATE_PROJECT',
  CREATE_PROJECT_SETTINGS = 'CREATE_PROJECT_SETTINGS',
  UPDATE_PROJECT_SETTINGS = 'UPDATE_PROJECT_SETTINGS',
  FIT_PROJECT = 'FIT_PROJECT',
  UPDATE_GROWTH_KINETICS = 'UPDATE_GROWTH_KINETICS',
  RUN_SIMULATION = 'RUN_SIMULATION',
  RUN_DT_SIMULATION = 'RUN_DT_SIMULATION',
  CREATE_FEEDS = 'CREATE_FEEDS',
  GET_FEEDS = 'GET_FEEDS',
  DELETE_FEEDS = 'DELETE_FEEDS',
  UPDATE_FIT_PARAMETERS = 'UPDATE_FIT_PARAMETERS',
  GET_PROJECT_METABOLITES = 'GET_PROJECT_METABOLITES',
  UPDATE_PROJECT_METABOLITES = 'UPDATE_PROJECT_METABOLITES',
  CREATE_EVENT = 'CREATE_EVENT',
  UPDATE_EVENT = 'UPDATE_EVENT',
  DELETE_EVENT = 'DELETE_EVENT',
  CREATE_INITIAL_CONDITIONS = 'CREATE_INITIAL_CONDITIONS',
  UPDATE_INITIAL_CONDITIONS = 'UPDATE_INITIAL_CONDITIONS',
  UPDATE_FUNCTION = 'UPDATE_FUNCTION',
  EXPORT = 'EXPORT',
  UPDATE_METABOLITE_RESET = 'UPDATE_METABOLITE_RESET',
  CREATE_STAGE = 'CREATE_STAGE',
  EDIT_STAGE = 'EDIT_STAGE',
  DELETE_STAGE = 'DELETE_STAGE',
  SIM_UPDATE_INITIAL_CONDITIONS = 'SIM_UPDATE_INITIAL_CONDITIONS'
}
