import React, { useCallback } from 'react';
import { YAxis } from 'recharts';

export const useRenderCustomizedYAxis = (
  yAxisUnit?: string,
  customDomain?: [any, any],
  customYAxisWidth = 90
) => {
  const render = useCallback(
    () => (
      <YAxis
        name={yAxisUnit}
        label={{
          value: yAxisUnit,
          angle: -90
        }}
        width={customYAxisWidth}
        domain={customDomain ?? ['auto', 'auto']}
        orientation="right"
      />
    ),
    [customDomain, yAxisUnit]
  );
  if (!yAxisUnit) return () => null;
  return render;
};
