import React, { useCallback, useContext, useEffect, useState } from 'react';

export type IntSpec = {
  name: string;
  type: 'int';
  min?: number;
  max?: number;
  defaultValue?: number;
  required?: boolean;
  customError?: string | null;
  strictMin?: boolean;
};

export type SelectSpec = {
  name: string;
  type: 'select';
  min?: number;
  max?: number;
  defaultValue?: number;
  required?: boolean;
  customError?: string | null;
  options: { value: string; label: string }[];
};

export type FloatSpec = {
  name: string;
  type: 'float';
  min?: number;
  max?: number;
  defaultValue?: number;
  required?: boolean;
  customError?: string | null;
  strictMin?: boolean;
  options?: { value: number; label: string }[];
};

export type RadioSpec = {
  name: string;
  title: string;
  type: 'radio';
  options: { value: string; label: string }[];
  defaultValue?: string;
  required?: boolean;
};
export type FieldSpec = IntSpec | FloatSpec | RadioSpec | SelectSpec;

export const FormContext = React.createContext({
  fieldsSpec: [] as FieldSpec[],
  values: {} as { [key: string]: any },
  errors: {} as { [key: string]: string },
  onSetErrors: (errors: { [key: string]: string }) => {},
  onChangeField(field: string, value: any) {}
});

export const useFormField = (fieldName: string) => {
  const ctx = useContext(FormContext);
  return {
    value: ctx.values[fieldName],
    onChange: ctx.onChangeField
  };
};

const useFormContextValues = () => useContext(FormContext).values;
const useFieldsSpec = () => useContext(FormContext).fieldsSpec;
const useSetErrors = () => useContext(FormContext).onSetErrors;
const useOnChangeField = () => useContext(FormContext).onChangeField;
const useErrors = () => useContext(FormContext).errors;
const useTitle = (fieldName: string) =>
  (
    useContext(FormContext).fieldsSpec.find(
      ({ name }) => name === fieldName
    ) as RadioSpec
  )?.title;
const useOptions = (fieldName: string) => {
  return (
    useContext(FormContext).fieldsSpec.find(
      ({ name }) => name === fieldName
    ) as RadioSpec
  )?.options;
};

const validateInt = (spec: IntSpec, value: number | string) => {
  if (!spec.required && typeof value !== 'number' && !value) return {};
  if (typeof value !== 'number') {
    return {
      [spec.name]: `Field should be number`
    };
  }
  const errors: { [key: string]: string } = {};
  if ((value * 10) % 10 !== 0) {
    errors[spec.name] = `Field should be integer`;
  }
  if (
    typeof spec.min === 'number' &&
    ((!spec.strictMin && spec.min > value) ||
      (spec.strictMin && spec.min >= value))
  ) {
    errors[spec.name] = `Field should be greater than ${spec.min}`;
  }
  if (typeof spec.max === 'number' && spec.max < value) {
    errors[spec.name] = `Field should be less than ${spec.max}`;
  }
  return errors;
};

const validateFloat = (spec: FloatSpec, value: number | string) => {
  const errors: { [key: string]: string } = {};
  if (!spec.required && typeof value !== 'number' && !value) return {};
  if (typeof value !== 'number') {
    return {
      [spec.name]: `Field should be number`
    };
  }
  if (
    typeof spec.min === 'number' &&
    ((!spec.strictMin && spec.min > value) ||
      (spec.strictMin && spec.min >= value))
  ) {
    errors[spec.name] =
      spec.customError || `Field should be greater than ${spec.min}`;
  }
  if (typeof spec.max === 'number' && spec.max < value) {
    errors[spec.name] =
      spec.customError || `Field should be less than ${spec.max}`;
  }
  return errors;
};

const validateRadio = (spec: RadioSpec, value: string | number) => {
  if (typeof value !== 'string') {
    return {
      [spec.name]: `Field should be string`
    };
  }
  const errors: { [key: string]: string } = {};
  if (!spec.options.find((opt) => opt.value === value)) {
    errors[spec.name] = `Field must be one of this options: ${spec.options.join(
      ', '
    )}`;
  }
  return errors;
};
const validateRequired = (spec: FieldSpec, value?: string | number) => {
  if (spec.required && typeof value === 'undefined') {
    return {
      [spec.name]: 'Field is required!'
    };
  }
  return {};
};
const validateFieldsBySpec = (
  fieldsSpec: FieldSpec[],
  values: { [key: string]: any }
) => {
  let errors: { [key: string]: string } = {};
  fieldsSpec.forEach((spec) => {
    const currentValue = values[spec.name];
    if (spec.type === 'int') {
      errors = { ...errors, ...validateInt(spec, currentValue) };
    } else if (spec.type === 'float') {
      errors = { ...errors, ...validateFloat(spec, currentValue) };
    } else if (spec.type === 'radio') {
      errors = { ...errors, ...validateRadio(spec, currentValue) };
    }
    errors = { ...errors, ...validateRequired(spec, currentValue) };
  });
  return errors;
};
export const useValidate = () => {
  const values = useFormContextValues();
  const fieldsSpec = useFieldsSpec();
  const onSetErrors = useSetErrors();
  return useCallback(() => {
    const errors = validateFieldsBySpec(fieldsSpec, values);
    onSetErrors(errors);
    return errors;
  }, [fieldsSpec, onSetErrors, values]);
};

export const useField = (fieldName: string) => {
  const values = useFormContextValues();
  const errors = useErrors();
  const onChange = useOnChangeField();
  const options = useOptions(fieldName);
  const title = useTitle(fieldName);
  return {
    value: values[fieldName],
    error: errors[fieldName],
    onChange: (value?: string | number) => onChange(fieldName, value),
    options,
    title
  };
};

export const useValues = () => useContext(FormContext).values;

export const FormWrapper: React.FC<{
  defaultValues: { [key: string]: any };
  fieldsSpec: FieldSpec[];
  fields: { [key: string]: any };
  setFields: (
    fields:
      | { [key: string]: any }
      | ((fields: { [key: string]: any }) => { [key: string]: any })
  ) => void;
}> = ({ fields, setFields, defaultValues = {}, fieldsSpec, children }) => {
  useEffect(() => {
    setFields(defaultValues);
  }, [defaultValues, setFields]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const onChangeField = useCallback(
    (field: string, value: any) => {
      setErrors({});
      setFields((prev) => ({ ...prev, [field]: value }));
    },
    [setFields]
  );
  return (
    <FormContext.Provider
      value={{
        fieldsSpec,
        values: fields,
        errors,
        onSetErrors: setErrors,
        onChangeField
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
