import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import s from './model-configuring.module.scss';
import { Header } from '../../components/Header/Header';
import {
  Graphic,
  Heading,
  HeadingVariant,
  SelectedPoint
} from '../../components';
import { Button, ButtonVariant } from '../../components/Button/Button';
import { ReactComponent as SimulationIcon } from './assets/simulation.svg';
import { Table } from '../../components/Table/Table';
import { useParams } from 'react-router-dom';
import { useRunSimulation } from '../../queries/simulation';
import { ProjectBreadcrumbs } from '../project-settings';
import {
  useColumnsMap,
  useInitialData,
  useProject
} from '../../queries/projects';
import { LastSaved } from '../model-configuring';
import {
  FieldSpec,
  FormWrapper,
  useValidate
} from '../project-settings/components/FormContext';
import { FormFieldFloatTextValue } from '../project-settings/components/FormFieldFloatTextValue';
import { useRenderTooltip } from '../fitting/hooks/useRenderTooltip';
import { useLines } from './hooks/useLines';
import { useXAxis } from './hooks/useXAxis';
import {
  useCreateInitialConditions,
  useInitialConditions
} from '../../queries/initial-conditions';
import { useDefaultValues } from '../simulation-dt/components/initial-conditions/hooks/useDefaultValues';
import {
  extractCellMetrics,
  extractFeeds,
  extractInputs,
  extractMetabolites,
  extractVolume
} from '../simulation-dt/components/initial-conditions/components/Buttons/Buttons';
import { useEventFeeds } from '../simulation-dt/components/event-configurator/hooks/useEventFeeds';
import { useMetabolites } from '../simulation-dt/components/event-configurator/components/add-event-metabolite-reset-modal/hooks/useMetabolites';
import { useInputs } from '../simulation-dt/components/event-configurator/hooks/useInputs';
import { SelectField } from '../simulation-dt/components/event-configurator/components/add-event-input-reset-modal/components/SelectField';
import { SelectOrFloatField } from '../simulation-dt/components/event-configurator/components/add-event-input-reset-modal/components/SelectOrFloatField';

export const Info = <Key extends string>({
  fields,
  title,
  disabled
}: {
  title: string;
  disabled?: boolean;
  fields: {
    type?: 'select' | 'select-or-float';
    name: string;
    value: Key;
    unit?: string;
  }[];
}) => {
  return (
    <div className={s.Info} style={{ opacity: disabled ? 0.5 : 1 }}>
      <div className={s.Info__title}>{title}</div>
      <div className={s.Info__content}>
        {fields.map((field) => {
          if (field.type === 'select') {
            return (
              <div className={s.Info__item} key={field.value}>
                <div className={s.Info__itemName}>{field.name}</div>
                <div className={s.Info__itemValue}>
                  <SelectField disabled={disabled} fieldName={field.value} />
                </div>
              </div>
            );
          }
          if (field.type === 'select-or-float') {
            return (
              <div className={s.Info__item} key={field.value}>
                <div className={s.Info__itemName}>{field.name}</div>
                <div className={s.Info__itemValue}>
                  <SelectOrFloatField
                    disabled={disabled}
                    fieldName={field.value}
                  />
                  {field.unit ? (
                    <div className={s.Info__itemUnit}>{field.unit}</div>
                  ) : null}
                </div>
              </div>
            );
          }
          return (
            <div className={s.Info__item} key={field.value}>
              <div className={s.Info__itemName}>{field.name}</div>
              <div className={s.Info__itemValue}>
                <FormFieldFloatTextValue
                  disabled={disabled}
                  fieldName={field.value}
                />
                {field.unit ? (
                  <div className={s.Info__itemUnit}>{field.unit}</div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export enum SimulationMode {
  perfusion_optimizer = 1,
  intensified_growth = 2,
  centrifuge_perfusion = 3,
  general_simulation = 4
}

const mapSimulationModeToParameters = {
  [SimulationMode.perfusion_optimizer]: [
    {
      value: 'volumetric_exchange_rate',
      name: 'Volumetric exchange rate',
      unit: 'vol/day'
    },
    {
      value: 'target_viability',
      name: 'Target viability',
      unit: '0 - 1.0'
    },
    {
      value: 'simulation_duration',
      name: 'Duration',
      unit: 'days'
    }
  ],
  [SimulationMode.intensified_growth]: [
    {
      value: 'cell_specific_exchange_rate',
      name: 'Cell specific exchange rate',
      unit: 'pL/cell/day'
    },
    {
      value: 'target_viable_cell_denisty',
      name: 'Target viable cell density',
      unit: '1e6 cells/ml'
    },
    {
      value: 'maximum_growth_time',
      name: 'Duration',
      unit: 'days'
    }
  ],
  [SimulationMode.centrifuge_perfusion]: [
    {
      value: 'target_viable_cell_denisty',
      name: 'Target viable cell density',
      unit: '1e6 cells/ml'
    },
    {
      value: 'simulation_duration',
      name: 'Duration',
      unit: 'days'
    },
    {
      value: 'media_exchange_start_time',
      name: 'Media exchange start time',
      unit: 'days'
    },
    {
      value: 'media_exchange_ratio',
      name: 'Media exchange ratio',
      unit: '0 - 1.0'
    },
    {
      value: 'media_exchange_frequency',
      name: 'Media exchange frequency',
      unit: 'days'
    }
  ],
  [SimulationMode.general_simulation]: [
    {
      value: 'volumetric_exchange_rate',
      name: 'Volumetric exchange rate',
      unit: 'vol/day'
    },
    {
      value: 'target_viable_cell_denisty',
      name: 'Target viable cell density',
      unit: '1e6 cells/ml'
    },
    {
      value: 'simulation_duration',
      name: 'Duration',
      unit: 'days'
    }
  ]
};

const mapSimulationModeToFieldSpecs: { [key in SimulationMode]: FieldSpec[] } =
  {
    [SimulationMode.perfusion_optimizer]: [
      { name: 'volumetric_exchange_rate', type: 'float', min: 0, max: 5 },
      { name: 'target_viability', type: 'float', min: 0, max: 1 },

      { name: 'simulation_duration', type: 'float', min: 10, max: 100 }
    ],
    [SimulationMode.intensified_growth]: [
      { name: 'cell_specific_exchange_rate', type: 'float', min: 0, max: 200 },
      { name: 'target_viable_cell_denisty', type: 'float', min: 0, max: 200 },

      { name: 'maximum_growth_time', type: 'float', min: 0, max: 25 }
    ],
    [SimulationMode.centrifuge_perfusion]: [
      { name: 'target_viable_cell_denisty', type: 'float', min: 0, max: 200 },
      { name: 'simulation_duration', type: 'float', min: 10, max: 100 },

      { name: 'media_exchange_start_time', type: 'float', min: 0, max: 100 },
      { name: 'media_exchange_ratio', type: 'float', min: 0, max: 1 },
      { name: 'media_exchange_frequency', type: 'float', min: 0.1, max: 3 }
    ],
    [SimulationMode.general_simulation]: [
      { name: 'volumetric_exchange_rate', type: 'float', min: 0, max: 5 },
      { name: 'target_viable_cell_denisty', type: 'float', min: 0, max: 200 },

      { name: 'simulation_duration', type: 'float', min: 10, max: 100 }
    ]
  };
export const simulationModes = [
  {
    name: 'Perfusion optimizer',
    value: SimulationMode.perfusion_optimizer
  },
  {
    name: 'Intensified growth',
    value: SimulationMode.intensified_growth
  },
  {
    name: 'Centrifuge perfusion',
    value: SimulationMode.centrifuge_perfusion
  },
  {
    name: 'General simulation',
    value: SimulationMode.general_simulation
  }
];

const RESTRICTED_COLUMNS = [
  'BatchID',
  'BatchId',
  'Time',
  'Viable cell density',
  'Time to target'
  // 'Cell viability'
];
const initialVariablesFieldsSpec: FieldSpec[] = [
  {
    name: 'initial_viable_cell_density',
    type: 'float',
    min: 0.1,
    max: 20
  },
  {
    name: 'initial_viability',
    type: 'float',
    min: 0.5,
    max: 1
  },
  {
    name: 'initial_lysed_cell_density',
    type: 'float',
    min: 0,
    max: 100
  },
  {
    name: 'initial_biomaterial',
    type: 'float',
    min: 0,
    max: 1000
  }
];

const RecalculationBlock = ({
  selectedSimulationMode,
  onChangeSimulationMode,
  runSimulation,
  disabled
}: {
  selectedSimulationMode: SimulationMode;
  onChangeSimulationMode: (newSimulationMode: SimulationMode) => void;
  runSimulation: () => void;
  disabled?: boolean;
}) => {
  const validate = useValidate();
  const handleRunSimulation = useCallback(() => {
    if (Object.keys(validate()).length === 0) {
      runSimulation();
    }
  }, [validate, runSimulation]);
  return (
    <div className={s.Fitting__recalculateWrapper}>
      <div>
        <div className={s.Fitting__recalculateLabel}>Simulation Mode</div>
        <div className={s.Fitting__recalculateSelectWrapper}>
          <select
            value={selectedSimulationMode}
            onChange={(e) =>
              onChangeSimulationMode(
                parseInt(e.target.value, 10) as SimulationMode
              )
            }
            className={s.Fitting__recalculateSelect}
          >
            <option value="-1">Select simulation mode</option>
            {simulationModes.map((simulationMode) => (
              <option key={simulationMode.name} value={simulationMode.value}>
                {simulationMode.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Button
        hoverVariant={ButtonVariant.ACTION}
        rightIcon={<SimulationIcon />}
        uppercase
        size="small"
        disabled={disabled}
        onClick={handleRunSimulation}
      >
        Run simulation
      </Button>
    </div>
  );
};

type InitialFields = {
  initial_viability: number;
  initial_viable_cell_density: number;
  initial_biomaterial: number;
  initial_lysed_cell_density: number;
};

type PerfusionOptimizerFields = {
  volumetric_exchange_rate: number;
  target_viability: number;
  simulation_duration: number;
};
type IntensifiedGrowthFields = {
  cell_specific_exchange_rate: number;
  target_viable_cell_denisty: number;
  maximum_growth_time: number;
};
type CentrifugePerfusionFields = {
  target_viable_cell_denisty: number;
  simulation_duration: number;
  media_exchange_start_time: number;
  media_exchange_ratio: number;
  media_exchange_frequency: number;
};
type GeneralSimulationFields = {
  volumetric_exchange_rate: number;
  target_viable_cell_denisty: number;
  simulation_duration: number;
};

type SimulationFields =
  | PerfusionOptimizerFields
  | IntensifiedGrowthFields
  | CentrifugePerfusionFields
  | GeneralSimulationFields;

type Fields = SimulationFields & InitialFields;

const mapFieldsToSimulationParameters = (
  fields: Fields,
  simulationMode: SimulationMode
) => {
  switch (simulationMode) {
    case SimulationMode.general_simulation: {
      return {
        volumetric_exchange_rate: (fields as GeneralSimulationFields)
          .volumetric_exchange_rate,
        target_viable_cell_denisty: (fields as GeneralSimulationFields)
          .target_viable_cell_denisty,
        simulation_duration: (fields as GeneralSimulationFields)
          .simulation_duration
      };
    }
    case SimulationMode.centrifuge_perfusion: {
      return {
        target_viable_cell_denisty: (fields as CentrifugePerfusionFields)
          .target_viable_cell_denisty,
        simulation_duration: (fields as CentrifugePerfusionFields)
          .simulation_duration,
        media_exchange_start_time: (fields as CentrifugePerfusionFields)
          .media_exchange_start_time,
        media_exchange_ratio: (fields as CentrifugePerfusionFields)
          .media_exchange_ratio,
        media_exchange_frequency: (fields as CentrifugePerfusionFields)
          .media_exchange_frequency
      };
    }
    case SimulationMode.intensified_growth: {
      return {
        cell_specific_exchange_rate: (fields as IntensifiedGrowthFields)
          .cell_specific_exchange_rate,
        target_viable_cell_denisty: (fields as IntensifiedGrowthFields)
          .target_viable_cell_denisty,
        maximum_growth_time: (fields as IntensifiedGrowthFields)
          .maximum_growth_time
      };
    }
    case SimulationMode.perfusion_optimizer: {
      return {
        volumetric_exchange_rate: (fields as PerfusionOptimizerFields)
          .volumetric_exchange_rate,
        target_viability: (fields as PerfusionOptimizerFields).target_viability,
        simulation_duration: (fields as PerfusionOptimizerFields)
          .simulation_duration
      };
    }
  }
};

const mapFieldsToInitialConditions = (fields: Fields) => {
  return {
    initial_viability: fields.initial_viability,
    initial_viable_cell_density: fields.initial_viable_cell_density,
    initial_biomaterial: fields.initial_biomaterial,
    initial_lysed_cell_density: fields.initial_lysed_cell_density
  };
};
const initialConditions = {
  initial_viability: 0.94,
  initial_viable_cell_density: 0.2,
  initial_biomaterial: 0,
  initial_lysed_cell_density: 0
};

const simulationParameters = {
  [SimulationMode.perfusion_optimizer]: {
    volumetric_exchange_rate: 2,
    target_viability: 0.925,
    simulation_duration: 40
  },
  [SimulationMode.intensified_growth]: {
    cell_specific_exchange_rate: 40,
    target_viable_cell_denisty: 80,
    maximum_growth_time: 14
  },
  [SimulationMode.centrifuge_perfusion]: {
    target_viable_cell_denisty: 80,
    simulation_duration: 40,
    media_exchange_start_time: 3,
    media_exchange_ratio: 0.7,
    media_exchange_frequency: 1
  },
  [SimulationMode.general_simulation]: {
    volumetric_exchange_rate: 2,
    target_viable_cell_denisty: 80,
    simulation_duration: 40
  }
};

const INITIAL_CONDITIONS_FIELDS = [
  {
    name: 'Initial Viable Cell Density',
    value: 'initial_viable_cell_density',
    unit: '1e6 cells/ml'
  },
  {
    name: 'Initial Viability',
    value: 'initial_viability',
    unit: '0 - 1.0'
  },
  {
    name: 'Initial Lysed Cells',
    value: 'initial_lysed_cell_density',
    unit: '1e6 cells/ml'
  },
  {
    name: 'Initial Biomaterial',
    value: 'initial_biomaterial',
    unit: '1e6 parts/ml'
  }
];

const columnsWeights: { [key: string]: number } = {
  'Volumetric productivity': 11,
  'Viability [%]': 10,
  Titer: 9,
  Inhibition: 8,
  Toxicity: 7,
  'Harvest Flow': 6,
  'Bleed Flow': 6,
  'Feed Flow': 6,

  'Specific growth rate': 5,

  'Specific death rate': 4,

  'Specific productivity': 3,

  'Bio material': 2,

  'Lysed cell density': 1
};

const getSortingParams = (simulationMode: SimulationMode) => {
  if (simulationMode === SimulationMode.intensified_growth) {
    return {
      direction: 'DESC',
      field: 'Time to target'
    };
  }
  return {
    direction: 'ASC',
    field: 'Titer'
  };
};
const commonColumnsWeights: { [key: string]: number } = {
  BatchId: 13,

  'Volumetric productivity': 12,

  'Viable cell density': 11,

  'Cell viability': 10,

  Titer: 9,

  Inhibition: 8,

  Toxicity: 7,

  'Harvest Flow': 6,
  'Bleed Flow': 6,
  'Feed Flow': 6,

  'Specific growth rate': 5,

  'Specific death rate': 4,

  'Specific productivity': 3,

  'Bio material': 2,

  'Lysed cell density': 1
};

const specialColumnsWeights: { [key: string]: number } = {
  BatchId: 14,

  'Time to target': 13,

  'Cell viability': 12,

  Titer: 11,

  'Volumetric productivity': 10,

  'Viable cell density': 9,

  Inhibition: 8,

  Toxicity: 7,

  'Harvest Flow': 6,
  'Bleed Flow': 6,
  'Feed Flow': 6,

  'Specific growth rate': 5,

  'Specific death rate': 4,

  'Specific productivity': 3,

  'Bio material': 2,

  'Lysed cell density': 1
};
const getColumnWeights = (simulationMode: SimulationMode) => {
  if (simulationMode === SimulationMode.intensified_growth) {
    return specialColumnsWeights;
  }
  return commonColumnsWeights;
};
export const SimulationPage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const {
    mutate: mutateRunSimulation,
    data = [] as any,
    isLoading
  } = useRunSimulation(projectId) as any;
  const [selectedSimulationMode, setSelectedSimulationMode] = useState<
    SimulationMode | -1
  >(-1);
  const tableRows = useMemo(() => {
    return (
      data?.map(({ batchName, data }: any) => {
        const parameterNames = Object.keys(data);
        const res: { [key: string]: string | number } = {
          BatchId: batchName
        };
        parameterNames.forEach((parameterName) => {
          res[parameterName] = (data as any)[parameterName][
            (data as any)[parameterName].length - 1
          ];
        });
        return res;
      }) || []
    ).sort((a: any, b: any) => {
      const { field, direction } = getSortingParams(selectedSimulationMode);
      const aValue = a[field];
      const bValue = b[field];
      if (typeof aValue !== 'number' || typeof bValue !== 'number') {
        return 0;
      }
      if (direction === 'DESC') {
        return aValue - bValue;
      } else {
        return bValue - aValue;
      }
    });
  }, [data, selectedSimulationMode]);
  const project = useProject(projectId);
  const initialData = useInitialData(projectId);

  const [fields, setFields] = useState<Partial<Fields>>({});

  const viableCellDensity = useMemo(() => {
    return data
      ?.flatMap(({ batchName, data }: any) =>
        (data as any)['Viable cell density'].map((v: number, idx: number) => ({
          [batchName]: v,
          x: (data as any)['Time'][idx],
          batchId: batchName
        }))
      )
      .sort((a: any, b: any) => a.x - b.x);
  }, [data]);

  const columnsOrder = useMemo(() => {
    if (selectedSimulationMode === -1) {
      return {};
    }
    return getColumnWeights(selectedSimulationMode);
  }, [selectedSimulationMode]);
  const tableColumns = useMemo(() => {
    return Object.keys(
      tableRows.reduce((acc: any, row: any) => ({ ...acc, ...row }), {})
    )
      .sort((a, b) => {
        const aWeight = columnsOrder[a] || 0;
        const bWeight = columnsOrder[b] || 0;
        return aWeight - bWeight;
      })
      .reverse()
      .filter((c) => c !== 'Time');
  }, [tableRows, columnsOrder]);

  const computedByScriptColumns = useMemo(() => {
    return tableColumns.filter((col) => !RESTRICTED_COLUMNS.includes(col));
  }, [tableColumns]);
  const [selected1, setSelected1] = useState('');
  const variableSelector1 = useMemo(() => {
    if (!selected1) return [];
    if (computedByScriptColumns.includes(selected1)) {
      return data
        ?.flatMap(({ batchName, data }: any) =>
          (data as any)['Viable cell density'].map(
            (v: number, idx: number) => ({
              [batchName]: (data as any)[selected1][idx],
              x: (data as any)['Time'][idx],
              batchId: batchName
            })
          )
        )
        .sort((a: any, b: any) => a.x - b.x);
    }
    return initialData.data?.map((row: any) => {
      const batchId = project.data?.columns_map?.BatchID
        ? row[project.data.columns_map.BatchID]
        : row.BatchID;
      const time = project.data?.columns_map?.Time
        ? row[project.data.columns_map.Time]
        : row.Time;
      return {
        [batchId]: row[selected1],
        x: time,
        batchId
      };
    });
  }, [
    computedByScriptColumns,
    data,
    initialData.data,
    project.data,
    selected1
  ]);

  const [selected2, setSelected2] = useState('');
  const variableSelector2 = useMemo(() => {
    if (!selected2) return [];
    if (computedByScriptColumns.includes(selected2)) {
      return data
        ?.flatMap(({ batchName, data }: any) =>
          (data as any)['Viable cell density'].map(
            (v: number, idx: number) => ({
              [batchName]: (data as any)[selected2][idx],
              x: (data as any)['Time'][idx],
              batchId: batchName
            })
          )
        )
        .sort((a: any, b: any) => a.x - b.x);
    }
    return initialData.data?.map((row: any) => {
      const batchId = project.data?.columns_map?.BatchID
        ? row[project.data.columns_map.BatchID]
        : row.BatchID;
      const time = project.data?.columns_map?.Time
        ? row[project.data.columns_map.Time]
        : row.Time;
      return {
        [batchId]: row[selected2],
        x: time,
        batchId
      };
    });
  }, [
    computedByScriptColumns,
    data,
    initialData.data,
    project.data,
    selected2
  ]);

  const initialDataColumns = useMemo(() => {
    if (!initialData.data) return [];
    const mappedRestrictedColumns = project.data?.columns_map
      ? RESTRICTED_COLUMNS.map(
          (col) => (project.data?.columns_map as any)[col] || col
        )
      : RESTRICTED_COLUMNS;

    return Object.keys(
      initialData.data.reduce(
        (acc, row) => ({ ...acc, ...row }),
        {} as { [key: string]: any }
      )
    ).filter(
      (column) =>
        !mappedRestrictedColumns.includes(column) &&
        !RESTRICTED_COLUMNS.includes(column)
    );
  }, [project.data, initialData.data]);

  const variableSelectorColumns = useMemo(
    () =>
      data && data.length > 0
        ? computedByScriptColumns
            .concat(initialDataColumns)
            .sort((a, b) => {
              const aWeight = columnsWeights[a] || 0;
              const bWeight = columnsWeights[b] || 0;
              return aWeight - bWeight;
            })
            .reverse()
        : [],
    [computedByScriptColumns, data, initialDataColumns]
  );

  const simulationModeFieldsSpec: FieldSpec[] = useMemo(
    () =>
      selectedSimulationMode === -1
        ? []
        : mapSimulationModeToFieldSpecs[selectedSimulationMode],
    [selectedSimulationMode]
  );
  const fieldsSpec = useMemo(
    () => initialVariablesFieldsSpec.concat(simulationModeFieldsSpec),
    [simulationModeFieldsSpec]
  );
  const defaultValues = useMemo(
    () => ({
      ...initialConditions,
      ...(selectedSimulationMode !== -1
        ? simulationParameters[selectedSimulationMode]
        : {})
    }),
    [selectedSimulationMode]
  );

  const immutableSelectedPoints = useRef<SelectedPoint<string>[]>();

  const [selectedPoints, onChangeSelectedPoints] = useState<
    SelectedPoint<string>[]
  >([]);
  const [selectedBatches, onChangeSelectedBatches] = useState<string[]>([]);
  // FIXME: not string type in <>
  const [selectedPoint, onSelectPoint] = useState<SelectedPoint<string> | null>(
    null
  );
  const vcdRenderTooltip = useRenderTooltip('VCD');
  const selected1RenderTooltip = useRenderTooltip(selected1);
  const selected2RenderTooltip = useRenderTooltip(selected2);
  const lines = useLines(data);
  const xAxis = useXAxis();
  useEffect(() => {
    if (selectedPoints.length > 0) {
      immutableSelectedPoints.current = selectedPoints;
    } else {
      immutableSelectedPoints.current &&
        data.length &&
        onChangeSelectedPoints(immutableSelectedPoints.current);
    }
  }, [selectedPoints, data]);

  const handleRunSimulation = useCallback(() => {
    if (selectedSimulationMode !== -1) {
      // onChangeSelectedBatches([]);
      onChangeSelectedPoints([]);
      onSelectPoint(null);
      mutateRunSimulation();
    }
  }, [fields, mutateRunSimulation, selectedSimulationMode]);

  const commonGraphicsProps = {
    xAxis,
    lines,
    selectedPoints,
    selectedBatches,
    onChangeSelectedBatches,
    onChangeSelectedPoints,
    selectedPoint,
    onSelectPoint,
    showIncludeExcludeControls: true
  };
  return (
    <div className={s.ProjectSettings}>
      <FormWrapper
        fields={fields}
        setFields={setFields}
        defaultValues={defaultValues}
        fieldsSpec={fieldsSpec}
      >
        <Header />
        <ProjectBreadcrumbs />
        <div className={s.ProjectSettings__content}>
          <div className={s.ModelConfiguring__contentLeft}>
            <Heading
              className={s.ProjectSettings__headingH2}
              variant={HeadingVariant.H2}
            >
              Simulation
            </Heading>
            <LastSaved />
          </div>
        </div>
        <RecalculationBlock
          selectedSimulationMode={selectedSimulationMode}
          onChangeSimulationMode={setSelectedSimulationMode}
          runSimulation={handleRunSimulation}
          disabled={isLoading || selectedSimulationMode === -1}
        />
        <div className={s.ModelConfiguring__frames}>
          <div>
            <div className={s.Fitting__infos}>
              {selectedSimulationMode !== -1 && (
                <Info
                  title="Simulation Parameters"
                  fields={mapSimulationModeToParameters[selectedSimulationMode]}
                />
              )}
              <Info
                title="Initial Conditions"
                fields={INITIAL_CONDITIONS_FIELDS}
              />
            </div>
          </div>
          <div>
            <div>
              <div className={s.Fitting__recalculateLabel}>
                Simulation Plots
              </div>
              <div className={s.Fitting__measuredVsPredictedFit}>
                <div
                  style={{
                    height: '700px',
                    width: '100%'
                  }}
                >
                  <Graphic
                    data={viableCellDensity || []}
                    yAxisUnit="Viable cell density"
                    renderTooltip={vcdRenderTooltip}
                    {...commonGraphicsProps}
                  />
                </div>
                <div className={s.Fitting__variableName}>
                  Viable cell density
                </div>
              </div>
              <div className={s.Fitting__variablesGraphics}>
                <div>
                  <div className={s.Fitting__variablesGraphicsSelect}>
                    <div className={s.Fitting__recalculateSelectWrapper}>
                      <select
                        value={selected1}
                        onChange={(e) => setSelected1(e.target.value)}
                        className={s.Fitting__recalculateSelect}
                      >
                        <option value="">Select variable</option>
                        {variableSelectorColumns.map((col) => (
                          <option key={col} value={col}>
                            {col}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                      height: '400px',
                      width: '35vw'
                    }}
                  >
                    <Graphic
                      data={
                        data && data.length > 0 ? variableSelector1 || [] : []
                      }
                      yAxisUnit={selected1}
                      renderTooltip={selected1RenderTooltip}
                      customDomain={
                        selected1 ===
                        project.data?.columns_map?.['Cell viability']
                          ? [0, 'auto']
                          : undefined
                      }
                      {...commonGraphicsProps}
                    />
                  </div>
                </div>
                <div>
                  <div className={s.Fitting__variablesGraphicsSelect}>
                    <div className={s.Fitting__recalculateSelectWrapper}>
                      <select
                        value={selected2}
                        onChange={(e) => setSelected2(e.target.value)}
                        className={s.Fitting__recalculateSelect}
                      >
                        <option value="">Select variable</option>
                        {variableSelectorColumns.map((col) => (
                          <option key={col} value={col}>
                            {col}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                      height: '400px',
                      width: '35vw'
                    }}
                  >
                    <Graphic
                      data={
                        data && data.length > 0 ? variableSelector2 || [] : []
                      }
                      yAxisUnit={selected2}
                      renderTooltip={selected2RenderTooltip}
                      customDomain={
                        selected2 ===
                        project.data?.columns_map?.['Cell viability']
                          ? [0, 'auto']
                          : undefined
                      }
                      {...commonGraphicsProps}
                    />
                  </div>
                </div>
              </div>
              <div className={s.ModelConfiguring__tableFrame}>
                <div className={s.ModelConfiguring__tableName}>
                  Summary Statistics
                </div>
                <div className={s.ModelConfiguring__table}>
                  {tableColumns.length === 0 ? (
                    <div className={s.ModelConfiguring__placeholder}>
                      <div className={s.ModelConfiguring__placeholderText}>
                        Start simulation to get summary statistics
                      </div>
                      <Button
                        hoverVariant={ButtonVariant.ACTION}
                        rightIcon={<SimulationIcon />}
                        uppercase
                        size="small"
                        disabled={isLoading || selectedSimulationMode === -1}
                        onClick={handleRunSimulation}
                      >
                        Run simulation
                      </Button>
                    </div>
                  ) : (
                    <Table
                      showSorting
                      rows={tableRows.map((row: any) => {
                        if (selectedBatches.includes(row.BatchId.toString())) {
                          return {
                            ...row,
                            selected: true
                          };
                        }
                        return { ...row, selected: false };
                      })}
                      onRowClick={(row: any) => {
                        onChangeSelectedBatches((prev) =>
                          prev.includes(row.BatchId)
                            ? prev.filter((d) => d !== row.BatchId)
                            : prev.concat(row.BatchId)
                        );
                      }}
                      columns={tableColumns.map((col) => ({
                        name: col,
                        title: col
                      }))}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};
