import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CartesianGrid,
  LineChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import {
  DOT_CLICK_EVENT,
  EXCLUDED_COLOR,
  HOVER_COLOR,
  SELECTED_COLOR
} from './constants';
import { useRenderCustomizedLine } from './hooks/useRenderCustomizedLine';
import { useRenderCustomizedYAxis } from './hooks/useRenderCustomizedYAxis';
import { useCustomizedTooltipWrapperProps } from './hooks/useCustomizedTooltipWrapperProps';
import { useRenderCustomizedXAxis } from './hooks/useRenderCustomizedXAxis';
import { useRenderReferenceDot } from './hooks/useRenderReferenceDot';
import { useRenderCustomizedLineOnlyPoints } from './hooks/useRenderCustomizedLineOnlyPoints';
import { CurveType } from 'recharts/types/shape/Curve';

export type SelectedPoint<Key extends string> = {
  id: number;
  dataKey: Key;
  x: number | null;
  y: number | null;
};

export type InternalSelectedPoint<Key extends string> = SelectedPoint<Key> & {
  cx?: number;
  cy?: number;
};
export interface GraphicLine<Key extends string = string> {
  name: string;
  dataKey: Key;
  stroke?: string;
  strokeWidth?: number;
  onlyLine?: boolean;
  onlyDots?: boolean;
  type?: CurveType;
}
export interface GraphicProps<Key extends string = string> {
  customDomain?: [any, any];
  data: { [key in Key & 'id']: number }[];
  xAxis: { label: string; dataKey: Key };
  yAxisUnit?: string;
  connectPointsByLines?: boolean;
  lines: GraphicLine<Key>[];
  children?: unknown;
  selectedPoint?: null | SelectedPoint<Key>;
  onSelectPoint?: (value: null | SelectedPoint<Key>) => void;
  selectedBatches?: Key[];
  onChangeSelectedBatches?: (value: Key[]) => void;
  excludedBatches?: Key[];
  onChangeExcludedBatches?: (value: Key[]) => void;
  selectedPoints?: SelectedPoint<Key>[];
  onChangeSelectedPoints?: (value: SelectedPoint<Key>[]) => void;
  excludedPoints?: SelectedPoint<Key>[];
  onChangeExcludedPoints?: (value: SelectedPoint<Key>[]) => void;
  renderTooltip: (value: SelectedPoint<Key>) => React.ReactNode;
  showIncludeExcludeControls?: boolean;
  lineType?: CurveType;
  customYAxisWidth?: number;
}

export const Graphic: React.FC<GraphicProps> = React.memo(
  <Key extends string>({
    data,
    xAxis,
    yAxisUnit,
    selectedPoint: externalSelectedPoint,
    onSelectPoint,
    connectPointsByLines = true,
    showIncludeExcludeControls,
    lines,
    renderTooltip,
    excludedBatches = [],
    excludedPoints = [],
    onChangeExcludedBatches,
    onChangeExcludedPoints,
    selectedPoints = [],
    selectedBatches = [],
    onChangeSelectedPoints,
    onChangeSelectedBatches,
    children,
    customDomain,
    lineType = 'linear',
    customYAxisWidth
  }: GraphicProps<Key>) => {
    const [internalSelectedPoint, internalOnSelectPoint] =
      useState<null | InternalSelectedPoint<Key>>(
        externalSelectedPoint ?? null
      );
    useEffect(() => {
      if (!externalSelectedPoint) internalOnSelectPoint(null);
    }, [externalSelectedPoint]);
    const selectedPoint: InternalSelectedPoint<Key> | null | undefined =
      useMemo(
        () =>
          internalSelectedPoint ??
          (externalSelectedPoint as
            | InternalSelectedPoint<Key>
            | null
            | undefined),
        [externalSelectedPoint, internalSelectedPoint]
      );
    const handleSelectPoint = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        const pointId = event.detail as unknown as InternalSelectedPoint<Key>;
        if (onSelectPoint) {
          onSelectPoint({
            x: pointId.x,
            y: pointId.y,
            dataKey: pointId.dataKey,
            id: pointId.id
          });
        }
        internalOnSelectPoint(pointId);
      },
      [onSelectPoint]
    );
    const onUnselectPoint = useCallback(() => {
      if (onSelectPoint) {
        onSelectPoint(null);
      }
      internalOnSelectPoint(null);
    }, [onSelectPoint]);
    const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);
    useEffect(() => {
      if (rootRef) {
        rootRef.addEventListener(DOT_CLICK_EVENT as any, handleSelectPoint);
        return () => {
          rootRef.removeEventListener(
            DOT_CLICK_EVENT as any,
            handleSelectPoint
          );
        };
      }
    }, [handleSelectPoint, rootRef]);

    const renderCustomizedXAxis = useRenderCustomizedXAxis(xAxis);
    const renderCustomizedYAxis = useRenderCustomizedYAxis(
      yAxisUnit,
      customDomain,
      customYAxisWidth
    );
    const renderReferenceDot = useRenderReferenceDot();
    const renderCustomizedLine = useRenderCustomizedLine({
      xAxisKey: xAxis.dataKey,
      connectPointsByLines
    });
    const renderCustomizedLineOnlyPoints = useRenderCustomizedLineOnlyPoints({
      xAxisKey: xAxis.dataKey,
      connectPointsByLines
    });
    const tooltipProps = useCustomizedTooltipWrapperProps({
      excludedBatches,
      excludedPoints,
      onUnselectPoint,
      onChangeExcludedPoints,
      onChangeExcludedBatches,
      showIncludeExcludeControls,
      renderTooltip,
      selectedPoint,
      selectedPoints,
      selectedBatches,
      onChangeSelectedPoints,
      onChangeSelectedBatches
    });

    return (
      <div style={{ height: '100%' }} ref={setRootRef}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            {renderCustomizedXAxis()}
            {renderCustomizedYAxis()}
            <Tooltip {...tooltipProps} trigger="click" />
            {lines.map((line) =>
              renderCustomizedLine({
                dataKey: line.dataKey,
                color: line.stroke,
                onlyLine: line.onlyLine,
                onlyDots: line.onlyDots,
                strokeWidth: line.strokeWidth,
                type: line.type
              })
            )}
            {selectedBatches.map((selectedBatch) =>
              renderCustomizedLineOnlyPoints({
                dataKey: selectedBatch,
                color: SELECTED_COLOR,
                type: lineType
              })
            )}
            {selectedPoints.map((selectedPoint) =>
              renderReferenceDot({
                color: SELECTED_COLOR,
                point: selectedPoint,
                key: selectedPoint.x ?? undefined
              })
            )}
            {selectedPoint &&
              renderReferenceDot({ color: HOVER_COLOR, point: selectedPoint })}
            {excludedBatches.map((excludedBatch, idx) =>
              renderCustomizedLineOnlyPoints({
                dataKey: excludedBatch,
                color: EXCLUDED_COLOR,
                type: lineType
              })
            )}
            {excludedPoints.map((excludedPoint) =>
              renderReferenceDot({
                color: EXCLUDED_COLOR,
                point: excludedPoint
              })
            )}
            {children}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
);
