import { response } from './simulationResponse';

export const prepareResponse = (data: any) => {
  console.log('>>> data.data:', data.data);

  const parameterNames = Object.keys(data.data);
  return [
    {
      batchName: '1',
      data: parameterNames.reduce(
        (acc, paramName) => ({
          ...acc,
          [paramName]: data.data[paramName]
            ? Object.keys(data.data[paramName]).map(
                (key) => data.data[paramName][key]
              )
            : []
        }),
        {}
      )
    }
  ];
};
