import { BrowserRouter, Route, RouteProps, Switch } from 'react-router-dom';
import { WelcomePage } from '../pages/welcome';
import { Paths } from './paths';
import { LoginPage } from '../pages/login';
import { ProjectsPage } from '../pages/projects';
import { ProjectSettingsPage } from '../pages/project-settings';
import { ModelConfiguringPage } from '../pages/model-configuring';
import { FittingPage } from '../pages/fitting';
import { SimulationPage } from '../pages/simulation/index-new';
import { AdditionalConfigurationPage } from '../pages/additional-confirugation';
import { MetabolitesPage } from '../pages/metabolites';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
import { AdminPage } from '../pages/admin/AdminPage';
import { SimulationDT } from '../pages/simulation-dt';

const pages: {
  component: RouteProps['component'];
  path: string;
}[] = [
  {
    component: WelcomePage,
    path: Paths.WELCOME
  },
  {
    component: LoginPage,
    path: Paths.LOGIN
  },
  {
    component: AuthGuard(ProjectsPage),
    path: Paths.PROJECTS
  },
  {
    component: AuthGuard(ProjectSettingsPage),
    path: Paths.CLONE_SELECTION_PROJECT_SETTINGS_EDIT
  },
  {
    component: AuthGuard(ProjectSettingsPage),
    path: Paths.CLONE_SELECTION_PROJECT_SETTINGS
  },
  {
    component: AuthGuard(ProjectSettingsPage),
    path: Paths.DIGITAL_TWIN_PROJECT_SETTINGS_EDIT
  },
  {
    component: AuthGuard(ProjectSettingsPage),
    path: Paths.DIGITAL_TWIN_PROJECT_SETTINGS
  },
  {
    component: AuthGuard(ModelConfiguringPage),
    path: Paths.CLONE_SELECTION_MODEL_CONFIGURATION
  },
  {
    component: AuthGuard(ModelConfiguringPage),
    path: Paths.DIGITAL_TWIN_MODEL_CONFIGURATION
  },
  {
    component: AuthGuard(FittingPage),
    path: Paths.CLONE_SELECTION_FITTING
  },
  {
    component: AuthGuard(SimulationPage),
    path: Paths.CLONE_SELECTION_SIMULATION
  },
  {
    component: AuthGuard(AdditionalConfigurationPage),
    path: Paths.DIGITAL_TWIN_ADDITIONAL_CONFIGURATION
  },
  {
    component: AuthGuard(MetabolitesPage),
    path: Paths.DIGITAL_TWIN_METABOLITES
  },
  {
    component: AuthGuard(FittingPage),
    path: Paths.DIGITAL_TWIN_FITTING_GROWTH_KINETICS
  },
  {
    component: AuthGuard(FittingPage),
    path: Paths.DIGITAL_TWIN_FITTING_METABOLITES
  },
  {
    component: AuthGuard(FittingPage),
    path: Paths.DIGITAL_TWIN_FITTING_TITER
  },
  {
    component: AuthGuard(AdminPage),
    path: Paths.ADMIN
  },
  {
    component: AuthGuard(SimulationDT),
    path: Paths.DIGITAL_TWIN_SIMULATION
  }
];

export const Pages = () => {
  return (
    <BrowserRouter>
      <Switch>
        {pages.map((page) => (
          <Route
            exact={true}
            key={page.path}
            path={page.path}
            component={page.component}
          />
        ))}
        <Route component={LoginPage} />
      </Switch>
    </BrowserRouter>
  );
};
