import { get, patch, post, postBlob, del } from './methods';
import { mapObjectToRows } from '../pages/projects';
import { SimulationMode } from '../pages/simulation';
import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification,
  NOTY_TIMEOUT
} from '../components/notifications';

export type KineticSummary = {
  growth_parameters: {
    lysing_rate: number;
    primary_death_rate: number;
    primary_growth_rate: number;
    toxicity_rate: number;
  };
  independent_variables: any[];
  inhibitor_factors: {
    threshold: number;
    variable: 'Bio material';
  }[];

  metabolites: any[];
  quadratic_factors: any[];
  substrate_factors: any[];
  user_calculations: any[];
};

export interface SimulationInitialConditions {
  initial_viability: number;
  initial_viable_cell_density: number;
  initial_biomaterial: number;
  initial_lysed_cell_density: number;
}

export type SimulationParameters =
  | {
      volumetric_exchange_rate: number;
      target_viability: number;
      simulation_duration: number;
    }
  | {
      cell_specific_exchange_rate: number;
      target_viable_cell_denisty: number;
      maximum_growth_time: number;
    }
  | {
      target_viable_cell_denisty: number;
      simulation_duration: number;
      media_exchange_start_time: number;
      media_exchange_ratio: number;
      media_exchange_frequency: number;
    }
  | {
      volumetric_exchange_rate: number;
      target_viable_cell_denisty: number;
      simulation_duration: number;
    };

export interface Stage {
  volume: number;
  simulation_duration: number;
  cell_specific_exchange_rate: number;
  volumetric_exchange_rate: number;
  target_viable_cell_denisty: number;
  parameter_type: 'FED_BUTCH' | 'INTENSIFIED_GROWTH' | 'CONTINUOUS_PERFUSION';
}

const paramToParam = {
  'Fed-butch': 'FED_BUTCH',
  'Intensified growth': 'INTENSIFIED_GROWTH',
  'Continuous perfusion': 'CONTINUOUS_PERFUSION'
};
const stage: Stage = {
  parameter_type: 'FED_BUTCH',
  volume: 0.005,
  simulation_duration: 12,
  cell_specific_exchange_rate: 40,
  volumetric_exchange_rate: 2,
  target_viable_cell_denisty: 80
};
export class SimulationApi {
  static getStages = ({ projectId }: { projectId: string }) =>
    get<
      {
        cell_specific_exchange_rate: number;
        id: string;
        parameter_type: string;
        project: string;
        simulation_duration: number;
        target_viable_cell_denisty: number;
        volume: number;
        volumetric_exchange_rate: number;
      }[]
    >(`simulation/${projectId}/stage-parameters`)
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred while receiving the prediction',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('get growth kinetics error');
        }
        console.log(res.data);
        return res.data
          .filter((stage: any) => stage.project === projectId)
          .map((stage: any) => ({
            ...stage,
            parameter_type: (paramToParam as any)[stage.parameter_type as any]
          }));
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred while receiving the prediction',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('get growth kinetics error');
      })
      .then((res) => {
        console.log(res);
        return res;
      });

  static addStage = ({ projectId }: { projectId: string }) => {
    const addStageNoty = createInfoNotification({
      text: 'The stage is being added'
    });
    addStageNoty.show();
    return post(`simulation/stage-parameters/`, {
      project: projectId,
      ...stage
    })
      .then((res) => {
        if (res.status !== 201) {
          createErrorNotification({
            text: 'An error occurred when adding stage',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('add stage error');
        }
        createSuccessNotification({
          text: 'The stage has been successfully added',
          timeout: NOTY_TIMEOUT
        }).show();
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred when adding stage',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('add stage error');
      })
      .finally(() => setTimeout(() => addStageNoty.close(), NOTY_TIMEOUT));
  };

  static editStage = ({
    parameterId,
    projectId,
    stage
  }: {
    projectId: string;
    parameterId: string;
    stage: Stage;
  }) => {
    return patch(`simulation/stage-parameters/${parameterId}`, {
      project: projectId,
      ...stage
    })
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred when editing stage',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('edit stage error');
        }
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred when editing stage',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('edit stage error');
      });
  };

  static deleteStage = ({ parameterId }: { parameterId: string }) => {
    return del(`simulation/stage-parameters/${parameterId}`)
      .then((res) => {
        if (res.status !== 204) {
          createErrorNotification({
            text: 'An error occurred when deleting stage',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('delete stage error');
        }
        createSuccessNotification({
          text: 'The stage has been successfully deleted',
          timeout: NOTY_TIMEOUT
        }).show();
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred when deleting stage',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('delete stage error');
      });
  };

  static getGrowthKinetics = ({ projectId }: { projectId: string }) =>
    get<{
      kinetic_summary: KineticSummary;
      simulated_data: object;
      fit_statistics: {
        [key: string]: {
          'Cell viability': {
            r_squared: number;
            rmse: number;
          };
          'Viable cell density': {
            r_squared: number;
            rmse: number;
          };
        };
      };
    }>(`simulation/${projectId}/growth-kinetics`)
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred while receiving the prediction',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('get growth kinetics error');
        }
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred while receiving the prediction',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('get growth kinetics error');
      })
      .then((res) => {
        return {
          kineticSummary: res.data.kinetic_summary,
          fit_statistics: res.data.fit_statistics,
          simulated_data: Object.keys(res.data.simulated_data).reduce(
            (acc, key) =>
              acc.concat({
                batchName: key,
                data: mapObjectToRows(
                  (res.data.simulated_data as any)[key],
                  true
                )
              }),
            [] as { batchName: string; data: object[] }[]
          )
        };
      });

  static recalculateGrowthKinetics = ({
    projectId,
    batchId,
    data
  }: {
    projectId: string;
    batchId: string;
    data: object;
  }) => {
    const recalculateGrowthKineticsNoty = createInfoNotification({
      text: 'The model is being recalculated'
    });
    recalculateGrowthKineticsNoty.show();
    return post(`simulation/${projectId}/growth-kinetics`, {
      data_set: data,
      metabolite_resat: localStorage.getItem('metabolite-reset') === 'true'
    })
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred when starting the model recalculation',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('recalculate growth kinetics error');
        }
        createSuccessNotification({
          text: 'The model recalculation has been successfully completed',
          timeout: NOTY_TIMEOUT
        }).show();
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred when starting the model recalculation',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('recalculate growth kinetics error');
      })
      .finally(() =>
        setTimeout(() => recalculateGrowthKineticsNoty.close(), NOTY_TIMEOUT)
      );
  };

  static runSimulation = ({
    projectId,
    ...body
  }: {
    projectId: string;
    // simulation_mode: SimulationMode;
  }) => {
    const runSimulationNoty = createInfoNotification({
      text: 'The simulation is being launched'
    });
    runSimulationNoty.show();
    return post<{
      data: {
        [key: string]: {
          [key: string]: {
            [key: string]: number;
          };
        };
      };
      summary_stats: {
        [key: string]: {
          [key: string]: {
            [key: string]: number;
          };
        };
      };
    }>(`simulation/${projectId}`, body)
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred when run simulation',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('run simulation error');
        }
        createSuccessNotification({
          text: 'The simulation has been successfully launched',
          timeout: NOTY_TIMEOUT
        }).show();
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred when run simulation',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('run simulation error');
      })
      .finally(() =>
        setTimeout(() => runSimulationNoty.close(), NOTY_TIMEOUT / 2)
      );
  };

  static runDtSimulation = (projectId: string, simulationDuration: number) =>
    post(`simulation-dt/${projectId}`, {
      simulation_duration: simulationDuration
    }).then((res) => mapObjectToRows(res.data));

  static export = (
    projectId: string,
    categories: {
      ORIGINAL_DATA: boolean;
      METABOLITE_DATES: boolean;
      PREDICTED_MODEL_DATA: boolean;
    }
  ) =>
    postBlob(`simulation/${projectId}/export`, {
      categories: Object.keys(categories).filter(
        (category) => categories[category as keyof typeof categories]
      )
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.xls');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
}
