import { get, patch, post, del } from './methods';
import { TargetRole, mapObjectToRows } from '../pages/projects';
import { RequestWrapper } from './requestWrapper';
import {
  createErrorNotification,
  createInfoNotification,
  createSuccessNotification,
  NOTY_TIMEOUT
} from '../components/notifications';
import {
  FitParameter,
  mapOutputRoleToInputRole,
  mapOutputTypeToInputType,
  ProjectSettings
} from './projectSettings';

export interface Project {
  columns_map: null | { [key in TargetRole]?: string };
  creating_step: string;
  date_created: string;
  date_modified: string;
  id: string;
  initial_data: string;
  name: string;
  user: string;
  render_started: string;
  render_status: string;
  titer_functions?: { batch?: { data?: string } } | null;
  titer_included?: boolean;
  metabolite_reset?: 'Reset' | 'Simulated';
}

export type AdminProject = {
  columns_map: null;
  creating_step: 'Data upload' | 'Growth kinetics';
  date_created: string;
  date_modified: string;
  fit_statistics: null;
  id: string;
  initial_data: string;
  is_rendering: boolean;
  kinetic_summary: null;
  metabolite_consumption_rates: null;
  name: string;
  titer_functions: {};
  user: string;
  render_uptime: string;
  render_status: string;
};
export class ProjectsApi {
  static getAll: () => Promise<RequestWrapper<AdminProject[]>> = () =>
    get<AdminProject[]>('projects')
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred while getting the list of projects',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('projects get error');
        }
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred while getting the list of projects',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('projects get error');
      });

  static getById = async (
    id: string
  ): Promise<RequestWrapper<Project & { isInRenderQueue: boolean }>> => {
    const [project, queue] = await Promise.all([
      get<Project>(`projects/${id}`)
        .then((res) => {
          if (res.status !== 200) {
            createErrorNotification({
              text: 'An error occurred while getting the list of projects',
              timeout: NOTY_TIMEOUT
            }).show();
            throw new Error('projects get error');
          }
          return res;
        })
        .catch(() => {
          createErrorNotification({
            text: 'An error occurred while getting the list of projects',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('projects get error');
        }),
      ProjectsApi.rendering()
    ]);
    return {
      ...project,
      data: {
        ...project.data,
        isInRenderQueue: Boolean(queue?.includes(project.data.id))
      }
    };
  };
  static getUsersProjects: () => Promise<RequestWrapper<Project[]>> = () =>
    get<Project[]>('projects/users-projects')
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred while getting the list of projects',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('projects get error');
        }
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred while getting the list of projects',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('projects get error');
      });

  static update = async (
    projectId: string | number,
    {
      initialFile,
      columnsMap,
      projectSettings,
      name,
      newFitParameters,
      deleteFitParameters
    }: {
      initialFile?: File;
      columnsMap?: { [key in TargetRole]?: string };
      name?: string;
      newFitParameters?: FitParameter[];
      projectSettings?: Partial<ProjectSettings>;
      deleteFitParameters?: string[];
    }
  ): Promise<RequestWrapper<Project>> => {
    delete projectSettings?.render_uptime;
    const editProjectNoty = createInfoNotification({
      text: 'The project is being updated'
    });
    editProjectNoty.show();
    let res;
    if (!initialFile) {
      res = await patch<Project>(`projects/${projectId}`, {
        columns_map: columnsMap,
        name
      })
        .then((res) => {
          if (res.status !== 200) {
            createErrorNotification({
              text: 'An error occurred while editing project',
              timeout: NOTY_TIMEOUT
            }).show();
            throw new Error('project edit error');
          }
          createSuccessNotification({
            text: 'Project was successfully updated',
            timeout: NOTY_TIMEOUT
          }).show();
          return res;
        })
        .catch(() => {
          createErrorNotification({
            text: 'An error occurred while editing project',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('project edit error');
        })
        .finally(() => {
          setTimeout(() => editProjectNoty.close(), NOTY_TIMEOUT / 2);
        });
    } else {
      const myHeaders = new Headers();
      myHeaders.append(
        'Authorization',
        `Bearer ${localStorage.getItem('TOKEN')}`
      );

      const formData = new FormData();
      formData.append(
        'initial_file',
        initialFile as File,
        (initialFile as File).name
      );
      if (name) {
        formData.append('name', name);
      }

      const requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formData,
        redirect: 'follow' as const
      };
      res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/projects`,
        requestOptions
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status !== 200) {
            createErrorNotification({
              text: 'An error occurred while editing project',
              timeout: NOTY_TIMEOUT
            }).show();
            throw new Error('projects edit error');
          }
          createSuccessNotification({
            text: 'Project was successfully updated',
            timeout: NOTY_TIMEOUT
          }).show();
          return res;
        })
        .catch(() => {
          createErrorNotification({
            text: 'An error occurred while editing project',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('project edit error');
        })
        .finally(() => {
          editProjectNoty.close();
        });
    }
    if (projectSettings) {
      await patch(`project/${projectId}/project-settings`, projectSettings);
    }
    if (newFitParameters) {
      await post(
        `projects/${projectId}/fit-parameters`,
        newFitParameters.map((param) => ({
          ...param,
          role: (mapOutputRoleToInputRole as any)[param.role],
          type: (mapOutputTypeToInputType as any)[param.type as any]
        }))
      );
    }
    if (deleteFitParameters) {
      await Promise.all(
        deleteFitParameters.map((deleteFitParameter) => {
          return del(`fit-parameter/${deleteFitParameter}`);
        })
      );
    }
    return res;
  };

  static create = ({
    initialFile,
    name,
    type
  }: {
    initialFile: File;
    name: string;
    type: 'SEED_TRAIN';
  }): Promise<RequestWrapper<Project>> => {
    const createProjectNoty = createInfoNotification({
      text: 'The project is being created'
    });
    createProjectNoty.show();
    const myHeaders = new Headers();
    myHeaders.append(
      'Authorization',
      `Bearer ${localStorage.getItem('TOKEN')}`
    );

    const formData = new FormData();
    formData.append(
      'initial_file',
      initialFile as File,
      (initialFile as File).name
    );
    formData.append('name', name);
    formData.append('type', type);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow' as const
    };
    return fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/projects`,
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status !== 201) {
          createErrorNotification({
            text: 'An error occurred while creating project',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('projects create error');
        }
        createSuccessNotification({
          text: 'Project was successfully created',
          timeout: NOTY_TIMEOUT
        }).show();
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred while creating project',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('project create error');
      })
      .finally(() => {
        setTimeout(() => createProjectNoty.close(), NOTY_TIMEOUT / 2);
      });
  };

  static getInitialData = (projectId: string | number) =>
    get<object[]>(`projects/${projectId}/initial-data`)
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred while getting initial data',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('get initial data error');
        }
        const rs = mapObjectToRows(res.data);
        return rs;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred while getting initial data',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('get initial data error');
      });

  static updateInitialData = (projectId: string | number, data: object) =>
    patch(`projects/${projectId}/initial-data`, data);

  static updateTiterFunction = async (
    projectId: string,
    code: string,
    isIncluded = false
  ) => {
    return patch(`projects/${projectId}`, {
      titer_functions: { batch: { data: code } },
      titer_included: isIncluded
    });
  };

  static deleteTiterFunction = async (projectId: string) => {
    return patch(`projects/${projectId}`, {
      titer_functions: {}
    });
  };

  static fit = (projectId: string | number, isDigitalTwin?: boolean) => {
    const startFittingModelNoty = createInfoNotification({
      text: 'The model rendering is being launched'
    });
    startFittingModelNoty.show();
    return post(`projects/${projectId}/fit`, {
      project_type: isDigitalTwin ? 'DIGITAL_TWIN' : 'CLONE_SELECTION'
    })
      .then((res) => {
        if (res.status !== 200) {
          createErrorNotification({
            text: 'An error occurred while starting fitting model',
            timeout: NOTY_TIMEOUT
          }).show();
          throw new Error('start fitting error');
        }
        createSuccessNotification({
          text: 'The model rendering has been successfully launched',
          timeout: NOTY_TIMEOUT
        }).show();
        return res;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred while starting fitting model',
          timeout: NOTY_TIMEOUT
        }).show();
        throw new Error('start fitting error');
      })
      .finally(() => {
        setTimeout(() => startFittingModelNoty.close(), NOTY_TIMEOUT / 2);
      });
  };

  static delete = (
    projectId: string | number
  ): Promise<RequestWrapper<any>> => {
    const deleteProjectNoty = createInfoNotification({
      text: 'The model is being deleted'
    });
    deleteProjectNoty.show();
    return del(`projects/${projectId}`)
      .then((res) => {
        createSuccessNotification({
          text: 'The project was successfully deleted',
          timeout: NOTY_TIMEOUT
        }).show();
        return res;
      })
      .catch((res) => {
        createErrorNotification({
          text: 'An error occurred while deleting project',
          timeout: NOTY_TIMEOUT
        });
        throw new Error('delete project error');
      })
      .finally(() => {
        setTimeout(() => {
          deleteProjectNoty.close();
        }, NOTY_TIMEOUT / 2);
      });
  };

  static rendering = () => {
    return get<string[]>('projects/rendering')
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        createErrorNotification({
          text: 'An error occurred while getting rendering projects',
          timeout: NOTY_TIMEOUT
        });
        throw new Error('rendering project error');
      });
  };

  static getMetabolites = (projectId: string) =>
    get<{ metabolic_rates: object; average_metabolite_consumption: object }>(
      `projects/${projectId}/metabolite_rates`
    )
      .then((res) => ({
        data: {
          average_metabolite_consumption: mapObjectToRows(
            res.data.average_metabolite_consumption
          ),
          metabolic_rates: mapObjectToRows(res.data.metabolic_rates)
        },
        status: res.status
      }))
      .catch((e) => {
        createErrorNotification({
          text: 'An error occurred while getting metabolites',
          timeout: NOTY_TIMEOUT
        }).show();
        throw e;
      });

  static recalculateMetabolites = (projectId: string, data: object) => {
    const noty = createInfoNotification({
      text: 'Metabolites are being updated'
    });
    return post(`projects/${projectId}/metabolite_rates`, data)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('update metabolites error');
        }
        noty.show();
        return res;
      })
      .catch((e) => {
        createErrorNotification({
          text: 'An error occurred while updating metabolites',
          timeout: NOTY_TIMEOUT
        }).show();
        throw e;
      })
      .finally(() => {
        setTimeout(() => noty.close(), NOTY_TIMEOUT / 2);
        createSuccessNotification({
          text: 'Metabolites were successfully updated',
          timeout: NOTY_TIMEOUT
        }).show();
      });
  };

  static getFitParameters = (projectId: string) => {
    return get<(FitParameter & { id: string })[]>(
      `projects/${projectId}/fit-parameters`
    );
  };

  static getAllFunctions = (projectId: string) => {
    return get<
      {
        id: string;
        project: string;
        name: string;
        type: string;
        value: string;
        custom_included: boolean;
      }[]
    >(`project/${projectId}/external-functions`);
  };

  static createFunction = (
    projectId: string,
    {
      type,
      name,
      value,
      customIncluded
    }: {
      name?: string;
      value: string;
      customIncluded?: boolean;
      type: 'TITER' | 'METABOLITE_RATES';
    }
  ) => {
    return post('external-function', {
      project: projectId,
      type,
      name,
      value,
      custom_included: customIncluded
    });
  };

  static updateFunction = (
    functionId: string,
    {
      value,
      customIncluded,
      type
    }: {
      value: string;
      customIncluded?: boolean;
      type: 'TITER' | 'METABOLITE_RATES';
    }
  ) => {
    return patch(`external-function/${functionId}`, {
      value,
      name: 'all',
      custom_included: customIncluded,
      type
    });
  };

  static updateMetaboliteReset = (projectId: string, reset = false) => {
    return patch<Project>(`projects/${projectId}`, {
      metabolite_reset: reset ? 'RESET' : 'SIMULATED'
    });
  };

  static getInitialConditions = (projectId: string) =>
    get(`initial-conditions/${projectId}/`);

  static updateInitialConditions = (
    projectId: string,
    data: {
      initial_viability: number;
      initial_viable_cell_density: number;
      initial_biomaterial: number;
      initial_lysed_cell_density: number;
    }
  ) => patch(`initial-conditions/${projectId}`, data);
}
